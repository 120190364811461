import {
  Grid2,
  Box,
  Typography,
  Button,
  useMediaQuery,
  TextField,
  CircularProgress,
  Link,
  Modal,
  FormLabel,
} from "@mui/material";
import React, { useState } from "react";
import "../../../styles/homeCustom.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RatingStars from "./ratingStarts";
import CheckIcon from "@mui/icons-material/Check";
import Service from "./body/service";
import Review from "./body/review";
import Experience from "./body/experience";
import "../../../styles/body.css";
import News from "./body/news";
import ContenfulCustom from "./news/contenfulCustom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../config/firebaseConfig";
import Portfolio from "./body/portfolio";
import Information from "./body/information";
import { updateFreelancer } from "../../../services/freelancerService";

import { useNavigate } from "react-router-dom";
import { deleteUserFinal } from "../../../services/userService";

export default function Body({
  isHome,
  isPyme,
  image_profile,
  rating,
  name,
  description,
  verifications,
  pyme,
  free,
}) {
  const [active, setActive] = useState("servicios");
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const handleMenuChange = (option) => {
    setActive(option);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const [inicialName, setInicialName] = useState(name);
  const [initialDescription, setInitialDescription] = useState(description);
  const [initialPhoto, setInitialPhoto] = useState(image_profile);

  const handleSaveClick = async () => {
    try {
      const updateFree = {
        fre_full_name: inicialName,
        fre_description: initialDescription,
        fre_photo: initialPhoto,
      };
      await updateFreelancer(free.fre_id, updateFree);
      // Actualiza los estados locales con los nuevos valores
      setInicialName(inicialName);
      setInitialDescription(initialDescription);
      setInitialPhoto(initialPhoto);

      setIsEditing(false);
      // window.location.reload();
    } catch (error) {
      console.error("Error al editar perfil:", error);
      setIsEditing(false);
    }

    setIsEditing(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const handleDeleteUser = async () => {
    try {
      await deleteUserFinal(free.usr_id.usr_id);
      navigate("/login");
    } catch (error) {
      console.error("Error al eliminar perfil:", error);
    }
  };

  const handleCancelClick = () => {
    setInicialName(name);
    setInitialDescription(description);
    setIsEditing(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleUpload(selectedFile); // Llama a la función de carga al seleccionar el archivo
    }
  };

  const handleUpload = (selectedFile) => {
    setLoading(true);
    const storageRef = ref(storage, `uploads/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Error uploading file:", error);
        setLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          setInitialPhoto(downloadURL);
          setLoading(false);
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "42%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  return (
    <Grid2 container className="container-body" sx={{ marginTop: "6rem" }}>
      {isMobile && isHome && (
        <Grid2 size={{ xs: 12 }} sx={{ m: "0px" }}>
          <Box className="box-publicidad">
            <ContenfulCustom />
          </Box>
        </Grid2>
      )}
      {/* SECCION 1 */}
      <Grid2 size={{ xs: 12, md: 2.5 }}>
        {!isHome || !isMobile ? ( // Mostrar solo si no es Home o si no es móvil
          <Box className="box-data-account">
            <Box className="box-data-account">
              {!isHome ? (
                !isEditing ? (
                  <Button
                    className="icon-edit"
                    onClick={handleEditClick}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "3px",
                      background: "none",
                      border: "none",
                      padding: "3px",
                      minWidth: "unset",
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditOutlinedIcon />
                  </Button>
                ) : (
                  <Box className="buttons-user-data">
                    <Button
                      className="button-user-data button2"
                      sx={{
                        border: "0px !important",
                        fontWeight: "300 !important",
                      }}
                      onClick={handleSaveClick}
                    >
                      Guardar
                    </Button>
                    <Button
                      className="button-user-data"
                      sx={{
                        border: "1px solid #e3602d !important",
                        fontWeight: "300 !important",
                        color: "#e3602d !important",
                        "&:hover": {
                          border: "1px solid #eba34f!important",
                          color: "#eba34f !important",
                        },
                      }}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </Button>
                    <label
                      htmlFor="upload-photo"
                      style={{
                        position: "absolute",
                        bottom: isMobile ? 20 : 15,
                        right: isMobile ? 55 : 40,
                      }}
                    >
                      <input
                        type="file"
                        id="upload-photo"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      {loading ? (
                        <CircularProgress size={28} sx={{ color: "#eba34f" }} />
                      ) : (
                        <AddRoundedIcon
                          sx={{
                            backgroundColor: "#eba34f",
                            color: "white",
                            fontSize: 28,
                            borderRadius: "50%",
                            padding: 1,
                          }}
                        />
                      )}
                    </label>
                  </Box>
                )
              ) : null}
              <Box className="image-profile-container">
                <img
                  src={fileUrl || initialPhoto}
                  alt="foto de perfil"
                  className="image-profile"
                />
              </Box>
            </Box>
            <Box>
              <RatingStars rating={rating} size={24} />
              {isEditing ? (
                <Box>
                  <TextField
                    value={inicialName}
                    onChange={(e) => setInicialName(e.target.value)}
                    variant="outlined"
                    className="name-account user-data"
                    InputProps={{
                      sx: {
                        color: "#747684",
                      },
                    }}
                  />
                  <TextField
                    value={
                      initialDescription ===
                      "Debe agregar una descripción editando su perfil"
                        ? ""
                        : initialDescription
                    }
                    onChange={(e) => {
                      if (e.target.value.length <= 150) {
                        setInitialDescription(e.target.value); // Solo actualiza si hay 150 caracteres o menos
                      }
                    }}
                    placeholder="Debe agregar una descripción editando su perfil"
                    variant="outlined"
                    className="description-account user-data"
                    multiline={true}
                    InputProps={{
                      sx: {
                        color: "#747684",
                      },
                    }}
                    helperText={`${initialDescription.length}/150`}
                  />
                </Box>
              ) : (
                <>
                  <Typography className="name-account">
                    {inicialName}
                  </Typography>
                  <Typography className="description-account">
                    {initialDescription}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        ) : null}
        {!isMobile && (!isHome || isPyme) ? ( // Ocultar en mobile
          <>
            <Box className="box-verifications-account">
              <Typography className="verificaciones">Verificaciones</Typography>
              {verifications.map((verification) => (
                <Box key={verification.id} className="verification-item">
                  <Typography>{verification.label}</Typography>
                  {verification.approved ? (
                    <CheckIcon sx={{ color: "#30B73D" }} />
                  ) : (
                    <Typography sx={{ color: "#E3602D !important" }}>
                      Pendiente
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <Box className="box-verifications-account-links">
              <Link
                href="https://dreup.cl"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                }}
              >
                <Typography>Términos y condiciones</Typography>
              </Link>
              <Link
                href="https://dreup.cl"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                }}
              >
                <Typography>Política de privacidad</Typography>
              </Link>
              <Button
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                  textTransform: "none",
                }}
                onClick={handleOpenDelete}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Typography>Eliminar cuenta</Typography>
              </Button>
            </Box>
          </>
        ) : null}
      </Grid2>
      {isMobile && !isHome &&  (
        <Grid2 size={{ xs: 12, md: 2.5 }}>
          <Box className="box-seccion3">
            <Typography className="title-seccion3">Dinero recaudado</Typography>
            <Typography className="price-seccion3">$2.000</Typography>
            <Typography className="title-seccion3">
              Servicios vendidos
            </Typography>
            <Typography className="service-seccion3">
              Marketing Digital
            </Typography>
          </Box>
        </Grid2>
      )}

      {/* SECCION 2 */}
      <Grid2 size={{ xs: 12, md: 7 }} sx={{ m: "0px" }}>
        {isPyme && !isHome ? (
          // Caso 1: perfil pyme
          <Box>
            <Typography>
              Este es el contenido para Pyme y no es Home.
            </Typography>
          </Box>
        ) : !isPyme && !isHome ? (
          // Caso 2: perfil freelancer
          <Box className="seccion2">
            <Box className="menu-seccion2">
              {/* Menú de botones */}
              <Button
                className="buttons-seccion2"
                onClick={() => handleMenuChange("servicios")}
                sx={{
                  color: active === "servicios" ? "#ED8131" : "#747684",
                  borderBottom:
                    active === "servicios"
                      ? "2.5px solid #E3602D"
                      : "2px solid transparent",
                }}
              >
                Servicios
              </Button>
              <Button
                className="buttons-seccion2"
                onClick={() => handleMenuChange("portafolio")}
                sx={{
                  color: active === "portafolio" ? "#ED8131" : "#747684",
                  borderBottom:
                    active === "portafolio"
                      ? "2.5px solid #E3602D"
                      : "2px solid transparent",
                }}
              >
                Portafolio
              </Button>
              <Button
                className="buttons-seccion2"
                onClick={() => handleMenuChange("informacion")}
                sx={{
                  color: active === "informacion" ? "#ED8131" : "#747684",
                  borderBottom:
                    active === "informacion"
                      ? "2.5px solid #E3602D"
                      : "2px solid transparent",
                }}
              >
                Información
              </Button>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              {active === "servicios" && (
                <>
                  <Service pyme={pyme} free={free} />
                  <Review pyme={pyme} free={free} />
                  <Experience pyme={pyme} free={free} />
                </>
              )}
              {active === "portafolio" && (
                <>
                  <Portfolio free={free} />
                </>
              )}
              {active === "informacion" && <Information free={free} />}
            </Box>
          </Box>
        ) : isPyme && isHome ? (
          // Caso 3: home pyme
          <>
            <News isPyme={isPyme} />
          </>
        ) : (
          // Caso 4: home freelancer
          <>
            <News isPyme={isPyme} />
          </>
        )}
      </Grid2>

      {/* SECCION 3 */}
      {!isMobile && (
        <Grid2 size={{ xs: 12, md: 2.5 }}>
          <Box className="box-seccion3">
            <Typography className="title-seccion3">Dinero recaudado</Typography>
            <Typography className="price-seccion3">$2.000</Typography>
            <Typography className="title-seccion3">
              Servicios vendidos
            </Typography>
            <Typography className="service-seccion3">
              Marketing Digital
            </Typography>
          </Box>
          {!isMobile && (
            <>
              {isHome ? (
                <Box className="box-publicidad">
                  <ContenfulCustom />
                </Box>
              ) : null}
            </>
          )}
        </Grid2>
      )}
      {isMobile && !isHome && (
          <Box className="box-verifications-account-links" sx={{width: '100%', marginBottom: 15}}>
            <Link
              href="https://dreup.cl"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              <Typography>Términos y condiciones</Typography>
            </Link>
            <Link
              href="https://dreup.cl"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              <Typography>Política de privacidad</Typography>
            </Link>
            <Button
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
                textTransform: "none",
              }}
              onClick={handleOpenDelete}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Typography>Eliminar cuenta</Typography>
            </Button>
          </Box>

      )}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(62, 16, 81, 0.8)",
          },
        }}
      >
        <Box sx={styleDelete}>
          <Box>
            <Typography
              id="modal-add"
              className="modal-add-title"
              sx={{ fontSize: "16px !important" }}
            >
              Eliminar Usuario
            </Typography>
            <FormLabel
              className="modal-add-subtitle"
              sx={{ color: "#747684 !important" }}
            >
              ¿Seguro que deseas eliminar tu usuario?
            </FormLabel>
            <Box className="modal-box-buttons">
              <Button
                className="modal-button button1"
                onClick={handleCloseDelete}
              >
                Cancelar
              </Button>
              <Button
                className="modal-button button2"
                onClick={handleDeleteUser}
              >
                Si, eliminar usuario
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Grid2>
  );
}
