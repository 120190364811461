import api from "./api";


export const createUser = async (userData) => {
    const response = await api.post('/auth/signup', userData);
    return response;
};

export const login = async (login) => {
    const response = await api.post('/auth/login', login);
    return response;
};

export const logout = async (usrId) => {
    const response = await api.post(`/auth/logout/${usrId}`);
    return response;
};

export const deleteUserFinal = async (usrId) => {
    const response = await api.patch(`/auth/user/delete/${usrId}`);
    return response;
};

export const loginGoogleFacebook = async (email) => {
  const response = await api.get(`/auth/google/login?email=${email}`);
  return response;
}

export const createSocialMedia = async (socialData) => {
    const response = await api.post('/user/create/socialMedia', socialData);
    return response;
};

export const confirmUser = async (userData) => {
    const response = await api.patch('/auth/confirm', userData);
    return response;
};

export const emailForwardingById = async (usrId) => {
  const response = await api.get(`/auth/email-forwarding/${usrId}`);
  return response;
}

export const getUserById = async (usrId) => {
  const response = await api.get(`/user/user/${usrId}`);
  return response;
}

export const getUserByEmail = async (email) => {
  const response = await api.get(`/user/user/email/${email}`);
  return response;
}

export const sendingCode = async (sendingCode) => {
  const response = await api.post('/auth/forgot-password/sending-code', sendingCode);
  return response;
};

export const codeActivation = async (usrId, codeActivation) => {
  const response = await api.patch(`/auth/forgot-password/codeActivation/${usrId}`, codeActivation);
  return response;
};

export const changePassword = async (usrId, changePassword) => {
  const response = await api.patch(`/auth/forgot-password/changePassword/${usrId}`, changePassword);
  return response;
};

export const deleteSocialMedia = async (id) => {
  const response = await api.patch(`user/delete/socialMedia/${id}`);
  return response;
};

export const getSocialMediaByID = async (id) => {
  const response = await api.get(`user/socialMediaID/${id}`);
  return response;
};




