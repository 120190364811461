import api from "./api";


export const getSpecializationList = async () => {
  const response = await api.get(`user/specialization/list`);
  return response;
}

export const deleteSpecializationFree = async (fspId) => {
  const response = await api.patch(`/user/delete/userSpecialization/${fspId}`);
  return response;
};

export const updateSpecializationFree = async (fspId, specialization) => {
  const response = await api.patch(`/freelancer/update/Specializationfreelancer/${fspId}`, specialization);
  return response;
};
