import api from "./api";


export const getPlansPyme = async () => {
  const response = await api.get(`/pyme/get/plansPyme`);
  return response;
}

export const getIndustryList = async () => {
  const response = await api.get(`/pyme/get/industrySector`);
  return response;
}

export const getTypeCompanyList = async () => {
  const response = await api.get(`/pyme/get/typeCompany`);
  return response;
}

export const getCompanyAgeList = async () => {
  const response = await api.get(`/pyme/get/companyAge`);
  return response;
}

export const getLocationPymeList = async () => {
  const response = await api.get(`/pyme/get/locationPyme`);
  return response;
}

export const createPymer = async (pyme) => {
  const response = await api.post('/pyme/create/pyme', pyme);
  return response;
};

export const getPymeByUsrId = async (pymId) => {
  const response = await api.get(`/pyme/get/pymeUsr/${pymId}`);
  return response;
};
