import api from "./api";

export const createFreelancer = async (freelancer) => {
  const response = await api.post("/freelancer/create/freelancer", freelancer);
  return response;
};

export const getOneFreelancerbyID = async (freId) => {
  const response = await api.get(`/freelancer/freelancerUsrId/${freId}`);
  return response;
};


export const getServiceByFreeID = async (freId) => {
  const response = await api.get(`/freelancer/freelancerServices/${freId}`);
  return response;
};

export const createFreelancerService = async (freelancerService) => {
  const response = await api.post("/freelancer/create/freelancerServices", freelancerService);
  return response;
};

export const deleteFreelancerService = async (fseId) => {
  const response = await api.patch(`/freelancer/delete/freelancerServices/${fseId}`);
  return response;
};

export const updateFreelancer = async (freId, newFreelancer) => {
  const response = await api.patch(`/freelancer/update/freelancer/${freId}`, newFreelancer);
  return response;
};

export const createFreelancerPortfolio = async (file) => {
  const response = await api.post(`/freelancer/create/freelancerPortfolio`, file);
  return response;
};

export const getFreelancerPortfolioByfreId = async (freId) => {
  const response = await api.get(`/freelancer/freelancerPortfolio/${freId}`);
  return response;
};


export const deleteFreePortfolio = async (freId) => {
  const response = await api.patch(`/freelancer/delete/freelancerPortfolio/${freId}`);
  return response;
};

export const getFreelancerReviewByfreId = async (freId) => {
  const response = await api.get(`/freelancer/freelancerReview/${freId}`);
  return response;
};

export const createFreelancerExperience = async (experience) => {
  const response = await api.post(`/freelancer/create/freelancerExperience`, experience);
  return response;
};

export const getFreelancerExperienceByfreId = async (freId) => {
  const response = await api.get(`/freelancer/freelancerExperience/${freId}`);
  return response;
};

export const updateFreelancerExperience = async (fex_id, updateFreelancerExperience) => {
  const response = await api.patch(`/freelancer/update/freelancerExperience/${fex_id}`, updateFreelancerExperience);
  return response;
};

export const deleteFreelancerExperience = async (fex_id) => {
  const response = await api.patch(`/freelancer/delete/freelancerExperience/${fex_id}`);
  return response;
};

export const updateFreelancerServices = async (updateFreelancerService) => {
  const response = await api.patch(`/freelancer/update/freelancerServices`, updateFreelancerService);
  return response;
};

export const getFreelancerReviewAVGRating = async (freId) => {
  const response = await api.get(`/freelancer/freelancerReview/AVGRating/${freId}`);
  return response;
};

