import React from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';

const RatingStars = ({
  rating,
  size
}) => {
  const clampedRating = Math.max(0, Math.min(rating, 5));

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {[...Array(5)].map((_, index) => (
        index < clampedRating ? (
          <GradeIcon
            key={index}
            style={{
              color: '#EBA34F',
              marginRight: '2px',
              fontSize: `${size}px`,
            }}
          />
        ) : (
          <GradeOutlinedIcon
            key={index}
            style={{
              color: '#C0C2D0',
              marginRight: '2px',
              fontSize: `${size}px`,
            }}
          />
        )
      ))}
    </div>
  );
};

export default RatingStars;