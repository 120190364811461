import React, { useEffect, useState } from "react";
import { Typography, Grid2, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createPayment } from "../../services/paymentService";

const PaymentError = () => {
  const navigate = useNavigate();

  const [planId, setPlanId] = useState();
  const [plaPrice, setPlaPrice] = useState();
  const [usrId, setUsrId] = useState();

  const loadUserLogin = () => {
    const savedPlanId = localStorage.getItem("selected-plan-id");
    const savedPlaPrice = localStorage.getItem("selected-plan-price");
    const savedUser = localStorage.getItem("user");

    if (savedPlanId && savedPlaPrice && savedUser) {
      const parsedPlanId = JSON.parse(savedPlanId);
      const parsedPlaPrice = JSON.parse(savedPlaPrice);
      const parsedLogin = JSON.parse(savedUser);
      setUsrId(parsedLogin);
      setPlanId(parsedPlanId);
      setPlaPrice(parsedPlaPrice);
    } else {
      console.error("No se encontró 'user-login' en localStorage");
      setTimeout(loadUserLogin, 500);
    }
  };

  useEffect(() => {
    loadUserLogin();
  }, []);

  const handleHirePlan = async () => {
    try {
      const data = {
        sessionId: `session-${Date.now()}`,
        buyOrder: `order-${Date.now()}`,
        amount: plaPrice,
        returnUrl: `${process.env.REACT_APP_API_URL}/transbank/success?usrId=${usrId.id}`,
      };
      const response = await createPayment(data);

      if (response.url && response.token) {
        window.location.href = `${response.url}?token_ws=${response.token}`;
      } else {
        console.error("Error: No se recibió la URL de Transbank");
      }
    } catch (error) {
      console.error("Error al iniciar la transacción", error);
    }
  };

  const handleVolver = async () => {
    navigate("/plans");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      textAlign="center"
      px={2}
    >
      <Box>
        <Typography variant="h4" gutterBottom className="text-error">
          Error de pago
        </Typography>
        <Typography
          variant="body1"
          sx={{
            margin: "10px 0",
            color: "#747684",
            fontStyle: "Inter",
            fontSize: "20px",
            fontWeight: "400",
          }}
        >
          Error al procesar el pago, por favor inténtelo nuevamente.
        </Typography>
        <Box className="modal-box-buttons">
          <Button className="modal-button button1" onClick={handleVolver}>
            Volver a seleccionar plan
          </Button>
          <Button className="modal-button button2" onClick={handleHirePlan}>
            Volver a intentar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentError;
