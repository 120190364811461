import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  createUser,
  login,
  loginGoogleFacebook,
} from "../../services/userService";
import { Alert } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import {
  loginFailure,
  loginSuccess,
  logout,
} from "../../store/slices/authSlice";
import {
  auth,
  googleProvider,
  facebookProvider,
} from "../../config/firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

function SignupBox({ isLogin = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [startLogin, setStartLogin] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [isLoadind, setIsLoadin] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setIsLoadin(true);
    //  debugger;
    localStorage.clear();
    e.preventDefault();

    let valid = true;
    let newErrors = { email: "", password: "", confirmPassword: "" };

    setErrorMessage("");
    setSuccessMessage("");

    if (!validateEmail(formData.email)) {
      newErrors.email = "Formato de correo inválido";
      setErrorMessage("El correo electrónico no tiene un formato válido");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (!validatePassword(formData.password)) {
      newErrors.password =
        "La contraseña debe tener al menos 8 caracteres, un carácter especial, un número, una mayúscula y una minúscula";
      setErrorMessage("La contraseña no cumple con los requisitos");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Las contraseñas no coinciden";
      setErrorMessage("Las contraseñas no coinciden");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    const user = {
      usr_email: formData.email,
      usr_password: formData.password,
    };

    try {
      const response = await createUser(user);
      setFormData({
        email: "",
        password: "",
        confirmPassword: "",
      });
      setErrors({
        email: "",
        password: "",
        confirmPassword: "",
      });

      const dataUser = {
        email: formData.email,
        id: response.data.data.usr_id,
        type: "",
      };
      dispatch(setUser(dataUser));
      localStorage.setItem("user", JSON.stringify(dataUser));
      setIsLoadin(false);
      navigate("/registrationEmail");
    } catch (error) {
      if (error.response.data.user) {
        setErrorMessage(
          "Ya hay una cuenta registrada con este correo electrónico."
        );
        setSnackbarType("error");
        setOpenSnackbar(true);
        setIsLoadin(false);
      } else {
        setErrorMessage("Error al crear el usuario verifique los datos.");
        setSnackbarType("error");
        setOpenSnackbar(true);
        setIsLoadin(false);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //Google y Facebook
  const { loading, user, error } = useSelector((state) => state.auth);

  const loginService = async (provider) => {
    setStartLogin(true); // Indica que el login ha comenzado
    // debugger;
    try {
      localStorage.clear();
      dispatch(logout());
      const result = await signInWithPopup(auth, provider);
      const finalUser = {
        email: result.user.email,
        displayName: result.user.displayName,
        phoneNumber: result.user.phoneNumber,
        photoURL: result.user.photoURL,
      };
      localStorage.setItem("user-google", JSON.stringify(finalUser));
      dispatch(loginSuccess(finalUser));
    } catch (error) {
      console.error(error);
      dispatch(loginFailure(error.message));
    }
  };

  useEffect(() => {
    const handleLogin = async () => {
      try {
        if (user) {
          const newuser = {
            usr_email: user.email,
            usr_password: null,
          };
          if (isLogin === true) {
            const responseGoogle = await loginGoogleFacebook(user.email);
            const dataUser = {
              email: user.email,
              id: responseGoogle.data.usr_id,
              type: "",
              isCreate: responseGoogle.data.isCreate,
            };
            dispatch(setUser(dataUser));
            const userLogin = {
              usr_email: user.email,
              usr_id: responseGoogle.data.usr_id,
              access_token: responseGoogle.data.access_token,
              refreshToken: responseGoogle.data.refreshToken,
              usr_type: responseGoogle.data.usr_type,
              isCreate: responseGoogle.data.isCreate,
            };

            localStorage.setItem("user-login", JSON.stringify(userLogin));
            localStorage.setItem("token", responseGoogle.data.access_token);
            localStorage.setItem(
              "refreshToken",
              responseGoogle.data.refreshToken
            );
            localStorage.setItem("user", JSON.stringify(dataUser));
            if (responseGoogle) {
              if (responseGoogle.data.isCreate === true) {
                navigate("/selectType");
              } else {
                if (responseGoogle.data.usr_type === null) {
                  navigate("/selectType");
                } else {
                  navigate("/home");
                }
              }
            } else {
              setErrorMessage("Se produjo un error, intente nuevamente.");
              setSnackbarType("error");
              setOpenSnackbar(true);
            }
          } else {
            const response = await createUser(newuser);

            const dataUser = {
              email: user.email,
              id: response.data.usr_id,
              type: "",
            };
            dispatch(setUser(dataUser));
            localStorage.setItem("user", JSON.stringify(dataUser));
            setSuccessMessage("Usuario registrado exitosamente");
            setSnackbarType("success");
            setOpenSnackbar(true);
            navigate("/selectType");
          }
        }
      } catch (error) {
        if (error.response.data.user) {
          setErrorMessage(
            "Ya hay una cuenta registrada con este correo electrónico."
          );
          setSnackbarType("error");
          setOpenSnackbar(true);
        } else {
          setErrorMessage("Error al crear el usuario, verifique los datos.");
          setSnackbarType("error");
          setOpenSnackbar(true);
        }
      }
    };

    if (startLogin) {
      handleLogin();
    }
  }, [startLogin, user]);

  const handleSubmitLogin = async () => {
    setIsLoadin(true)
    // debugger;
    localStorage.clear();
    let valid = true;
    let newErrors = { email: "", password: "" };

    setErrorMessage(""); // Limpiar mensajes anteriores
    setSuccessMessage(""); // Limpiar mensajes anteriores

    if (!validateEmail(formData.email)) {
      newErrors.email = "Formato de correo inválido";
      setErrorMessage("El correo electrónico no tiene un formato válido");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    const user = {
      usr_email: formData.email,
      usr_password: formData.password,
    };

    try {
      localStorage.clear();
      const response = await login(user);

      const userLogin = {
        usr_email: formData.email,
        usr_id: response.data.usr_id,
        access_token: response.data.access_token,
        refreshToken: response.data.refreshToken,
        usr_type: response.data.usr_type,
      };

      localStorage.setItem("user-login", JSON.stringify(userLogin));
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.usr_type);
      setIsLoadin(false)
      navigate("/home");
    } catch (error) {
      if (error.response.data.statusCode === 406) {
        setErrorMessage(
          "Correo o contraseña incorrectos. Por favor, verifica tus credenciales e intenta nuevamente."
        );
        setSnackbarType("error");
        setOpenSnackbar(true);
        setIsLoadin(false)
      } else {
        setErrorMessage(
          "Error al iniciar sesión. Por favor, verifica tus credenciales e intenta nuevamente."
        );
        setSnackbarType("error");
        setOpenSnackbar(true);
        setIsLoadin(false)
      }
    }
  };

  return (
    <Container
      className="container-custom"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className="box-signup"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: 3,
          height:
            errors.email || errors.password || errors.confirmPassword
              ? 720
              : 618,
        }}
      >
        <Box className={"box-container"}>
          {isLogin ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  height: 130,
                  marginBottom: 2,
                  marginTop: 1,
                }}
              >
                <Typography
                  className="text-title-login"
                  sx={{ mb: 2, textAlign: "center" }}
                >
                  Inicia sesión con:
                </Typography>

                <Box className="container-buttons-login">
                  <IconButton
                    className="button-login"
                    onClick={() => loginService(googleProvider)}
                    disabled={loading}
                  >
                    <img
                      className="img-g"
                      src="/static/img/google.png"
                      alt="Google Logo"
                      style={{ height: "28px", width: "28px" }}
                    />
                  </IconButton>

                  <IconButton
                    className="button-login"
                    onClick={() => loginService(facebookProvider)}
                    disabled={loading}
                  >
                    <img
                      className="img-f"
                      src="/static/img/facebook.png"
                      alt="Facebook Logo"
                      style={{ height: "40px", width: "40px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  height: 130,
                }}
              >
                <Typography className="text-1" sx={{ mb: 2 }}>
                  Regístrate con
                </Typography>

                <Box className="container-buttons">
                  <IconButton
                    className="button-login"
                    onClick={() => loginService(googleProvider)}
                    disabled={loading}
                  >
                    <img
                      className="img-g"
                      src="/static/img/google.png"
                      alt="Google Logo"
                      style={{ height: "28px", width: "28px" }}
                    />
                  </IconButton>

                  <IconButton
                    className="button-login"
                    onClick={() => loginService(facebookProvider)}
                    disabled={loading}
                  >
                    <img
                      className="img-f"
                      src="/static/img/facebook.png"
                      alt="Facebook Logo"
                      style={{ height: "40px", width: "40px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}

          <Divider className="divider-o" sx={{ my: 3 }}>
            o
          </Divider>
          {isLogin ? (
            <>
              <Box className={"box-container"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    maxWidth: 480,
                    height: 400,
                  }}
                >
                  <Typography className="text-3">Correo electrónico</Typography>
                  <TextField
                    name="email"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Cambia este valor para ajustar el redondeo
                      },
                    }}
                    className="text-field"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!errors.email}
                    helperText={errors.email}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Typography className="text-4">Contraseña</Typography>
                  <TextField
                    name="password"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Cambia este valor para ajustar el redondeo
                      },
                    }}
                    className="text-field"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    required
                    error={!!errors.password}
                    helperText={errors.password}
                    value={formData.password}
                    onChange={handleChange}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <LoadingButton
                    loading={isLoadind}
                    className="button-custom"
                    variant="contained"
                    onClick={handleSubmitLogin}
                    sx={{
                      textTransform: "none",
                    }}
                    // loadingIndicator={
                    //   <CircularProgress size={24} sx={{ color: "white" }} />
                    // }
                  >
                    Iniciar sesión
                  </LoadingButton>

                  <h1 className="olvide-contrasenia">
                    <a href="/recover-password" className="iniciar-sesion">
                      Olvidé mi contraseña
                    </a>
                  </h1>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                className={"container-input"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  maxWidth: 480,
                  height: 400,
                }}
              >
                <Typography className="text-2">Correo electrónico</Typography>
                <TextField
                  name="email"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Cambia este valor para ajustar el redondeo
                    },
                  }}
                  className="text-field"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                  value={formData.email}
                  onChange={handleChange}
                />
                <Typography className="text-2">Contraseña</Typography>
                <TextField
                  name="password"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Cambia este valor para ajustar el redondeo
                    },
                  }}
                  className="text-field"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.password}
                  helperText={errors.password}
                  value={formData.password}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Typography className="text-2">Repite tu contraseña</Typography>
                <TextField
                  name="confirmPassword"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Cambia este valor para ajustar el redondeo
                    },
                  }}
                  className="text-field"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <LoadingButton
                  loading={isLoadind}
                  className="button-custom"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    textTransform: "none",
                    }}
                    loadingIndicator={
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    }
                >
                  Crear cuenta
                </LoadingButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {/* Alerta flotante */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default SignupBox;
