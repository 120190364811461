const FILE_TYPES = {
  images: {
    formats: ["image/jpeg", "image/png", "image/svg+xml"],
    maxSize: 5 * 1024 * 1024, // 5 MB
  },
  videos: {
    formats: ["video/mp4", "video/quicktime", "video/x-msvideo", "video/webm"],
    maxSize: 100 * 1024 * 1024, // 100 MB
  },
  documents: {
    formats: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    ],
    maxSize: 10 * 1024 * 1024, // 10 MB
  },
  audios: {
    formats: ["audio/mpeg", "audio/wav"],
    maxSize: 10 * 1024 * 1024, // 10 MB
  },
};

export const validateFile = (file) => {
  const { type, size } = file;

  if (FILE_TYPES.images.formats.includes(type)) {
    return size <= FILE_TYPES.images.maxSize
      ? { isValid: true }
      : {
          isValid: false,
          error: "El archivo de imagen supera el tamaño máximo de 5 MB",
        };
  }

  if (FILE_TYPES.videos.formats.includes(type)) {
    return size <= FILE_TYPES.videos.maxSize
      ? { isValid: true }
      : {
          isValid: false,
          error: "El archivo de video supera el tamaño máximo de 100 MB",
        };
  }

  if (FILE_TYPES.documents.formats.includes(type)) {
    return size <= FILE_TYPES.documents.maxSize
      ? { isValid: true }
      : {
          isValid: false,
          error: "El archivo de documento supera el tamaño máximo de 10 MB",
        };
  }

  if (FILE_TYPES.audios.formats.includes(type)) {
    return size <= FILE_TYPES.audios.maxSize
      ? { isValid: true }
      : {
          isValid: false,
          error: "El archivo de audio supera el tamaño máximo de 10 MB",
        };
  }

  return { isValid: false, error: "El formato del archivo no está permitido" };
};
