import {
    Box,
    TextField,
    InputAdornment,
    Typography,
    Button,
    useMediaQuery,
} from '@mui/material'
import React from 'react';
import '../../../../styles/body.css';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

export default function News({
    isPyme
}) {
    const newsFreelancer = [
        {
            id: 1, name: "Coca Cola", title: "Título principal", description: "Lorem ipsum dolor sit amet consectetur. Venenatis elementum a quisque porttitor. Eu quisque cum dignissim massa placerat eget. Sed commodo odio mattis sed eget id. Neque magna fermentum commodo gravida natoque.",
            time: "2 semanas", price: "$2.500 - $3.600", state: "Etapa inical", experience: "Principiante", profile: "/static/img/news/profile1.png", offer: false
        },
        {
            id: 2, name: "Coca Cola", title: "Objetivo principal del proyecto", description: "Lorem ipsum dolor sit amet consectetur. Venenatis elementum a quisque porttitor. Eu quisque cum dignissim massa placerat eget. Sed commodo odio mattis sed eget id. Neque magna fermentum commodo gravida natoque.",
            time: "2 semanas", price: "$2.500 - $3.600", state: "Etapa inical", experience: "Principiante", profile: "/static/img/news/profile1.png", offer: true
        },
    ];

    const newsPyme = [
        {
            id: 1, name: "Coca Cola", profile: "/static/img/news/profile2.png", description: "Lorem ipsum dolor sit amet consectetur. Venenatis elementum a quisque porttitor. Eu quisque cum dignissim massa placerat eget. Sed commodo odio mattis sed eget id. Neque magna fermentum commodo gravida natoque.",
            image: "/static/img/news/new1.png", comment: 20,
        },
        {
            id: 2, name: "Coca Cola", profile: "/static/img/news/profile2.png", description: "Lorem ipsum dolor sit amet consectetur. Venenatis elementum a quisque porttitor. Eu quisque cum dignissim massa placerat eget. Sed commodo odio mattis sed eget id. Neque magna fermentum commodo gravida natoque.",
            image: "/static/img/news/new2.png", comment: 20,
        },
        {
            id: 1, name: "Coca Cola", profile: "/static/img/news/profile2.png", description: "Lorem ipsum dolor sit amet consectetur. Venenatis elementum a quisque porttitor. Eu quisque cum dignissim massa placerat eget. Sed commodo odio mattis sed eget id. Neque magna fermentum commodo gravida natoque.",
            image: "", comment: 20,
        },
    ];

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box className="seccion2">
            <Box className='box-search'>
                <TextField
                    className='search'
                    variant="outlined"
                    placeholder="Buscar oferta"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {isPyme ? (
                <>
                    {newsPyme.map((p) => (
                        <Box key={p.id} className='services-list' sx={{ gap: 2 }}>
                            <Box display="flex" alignItems="center" className='box-iconos'>
                                <Box
                                    display="flex" alignItems="center"
                                    className='box-iconos2'
                                    sx={{
                                        width: isMobile ? "45%" : "21%"
                                    }}
                                >
                                    <img
                                        src={p.profile}
                                        alt={p.name}
                                        style={{ width: 40, height: 40, borderRadius: '50%', marginRight: '16px' }}
                                    />
                                    <Typography className='text-name'>{p.name}</Typography>
                                </Box>
                                <Box
                                    className='box-iconos2'
                                    sx={{
                                        width: isMobile ? "20%" : "10%",
                                    }}
                                >
                                    <EditOutlinedIcon className="edit-icon" />
                                    <DeleteOutlineOutlinedIcon className="delete-icon" />
                                </Box>
                            </Box>
                            <Typography className='description'>{p.description}</Typography>
                            {p.image && (
                                <img
                                    src={p.image}
                                    alt={"imagen"}
                                    style={{ width: "100%", height: 290, borderRadius: '10px' }}
                                />
                            )}
                            <Typography className='description'>{p.comment} comentarios</Typography>
                            <Box className='box-icons-news'>
                                <ChatBubbleOutlineOutlinedIcon sx={{mr:"10px"}}/> Comentar
                                <ShareOutlinedIcon sx={{mr:"10px", ml: "30px"}}/> Compartir
                            </Box>
                        </Box>
                    ))}
                </>
            ) : (
                <>
                    {newsFreelancer.map((f) => (
                        <Box key={f.id} className='services-list'>
                            <Box display="flex" alignItems="center">
                                <img
                                    src={f.profile}
                                    alt={f.name}
                                    style={{ width: 40, height: 40, borderRadius: '50%', marginRight: '16px' }}
                                />
                                <Typography className='text-name'>{f.name}</Typography>
                            </Box>
                            <Typography className='text-title'>{f.title}</Typography>
                            <Typography className='description'>{f.description}</Typography>
                            <Box className='text-data'>
                                <Typography> <AccessTimeIcon />Tiempo de entrega: {f.time}</Typography>
                                <Typography><AttachMoneyIcon />Costo a pagar: {f.price}</Typography>
                                <Typography><ArticleOutlinedIcon />Estado de la propuesta: {f.state}</Typography>
                                <Typography><GradeOutlinedIcon />Experiencia requerida{f.experience}</Typography>
                            </Box>
                            {f.offer ? (
                                <>
                                    <Button className='button-offer-true'>
                                        Has ofertado
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button className='button-offer-false'>
                                        Ofertar
                                    </Button>
                                </>
                            )}
                        </Box>
                    ))}
                </>
            )}
        </Box>
    );
}