import { CheckCircleRounded, CheckRounded, Close } from "@mui/icons-material";
import { Box, Divider, Grid2, Stack, Typography, Button, Modal, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const listItemCheckFree = [
  "Ser parte de una red colaborativa eficaz",
  "Experiencia y posicionamiento profesional",
  "Autocuidado laboral",
  "Incrementar tus ingresos",
];

const listItemCheckPyme = [
  "Ser parte de una red colaborativa eficaz",
  "Posicionarte mejor en el mercado",
  "Autocuidado laboral",
  "Mejorar la gestión integral de tu negocio",
];

export default function LayoutSignUp() {
  const [stepsLogin, setStepsLogin] = useState([
    { label: "Datos personales", isSelected: 1 },
    { label: "Especialización", isSelected: 2 },
    { label: "Experiencia", isSelected: 2 },
  ]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isPyme = queryParams.get("isPyme");
  localStorage.setItem('isPyme', JSON.stringify(isPyme));

  let listItemCheck;
  let title;
  let type;
  let labelTitle;

  if (isPyme === 'true') {
    listItemCheck = listItemCheckPyme;
    title = "Crear Tu cuenta Pyme";
    type = "Siendo Pyme podrás:";
    labelTitle = "Industria";
  } else {
    listItemCheck = listItemCheckFree;
    title = "Crear tu cuenta Freelance";
    type = "Siendo Freelance podrás:";
    labelTitle = "Especialización";
  }

  // 0 tilde
  // 1 naranja
  // 2 deshabilitado

  useEffect(() => {
    switch (location.pathname) {
      case '/create-account/personal/':
        setStepsLogin([
          { label: "Datos personales", isSelected: 1 },
          { label: labelTitle, isSelected: 2 },
          { label: "Experiencia", isSelected: 2 },
        ]);
        break;
      case '/create-account/specialization/':
        setStepsLogin([
          { label: "Datos personales", isSelected: 0 },
          { label: labelTitle, isSelected: 1 },
          { label: "Experiencia", isSelected: 2 },
        ]);
        break;
      case '/create-account/experience/':
        setStepsLogin([
          { label: "Datos personales", isSelected: 0 },
          { label: labelTitle, isSelected: 0 },
          { label: "Experiencia", isSelected: 1 },
        ]);
        break;
      default:
        setStepsLogin([
          { label: "Datos personales", isSelected: 1 },
          { label: labelTitle, isSelected: 2 },
          { label: "Experiencia", isSelected: 2 },
        ]);
        break;
    }
  }, [location.pathname]);


  // MODAL, BOTON "VER MÁS"
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ bgcolor: "#fafafa" }}>
      <Grid2 container>
        <Grid2 size={{ xs: 12, md: 3.5 }} sx={{ pt: "5%", bgcolor: "#3E1051" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              minHeight: "100%",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ px: "2.5rem", pb: "5rem" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Libre Baskerville",
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#ED8131",
                    lineHeight: "44.6px",
                  }}
                >
                  Estás a 3 pasos de
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Libre Baskerville",
                    fontSize: "36px",
                    fontWeight: "700",
                    lineHeight: "44.6px",
                    color: "#EBA34F",
                    paddingBottom: "15px",
                    maxWidth: "100%",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "18px",
                    color: "#fff",
                    mb: "0.5rem",
                  }}
                >
                  {type}
                </Typography>
              </Box>
                {isMobile ? (
                  <>
                    <Button
                      onClick={handleOpen}
                      sx={{
                        color: '#EBA34F',
                        textTransform: 'none',
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "18px",
                        textAlign: "center",
                        mb: "0.5rem",
                        borderRadius: "10px",
                        border: "2px solid #EBA34F",
                        minWidth: "100%",
                      }}>
                      Ver más
                    </Button>
                    <Modal open={openModal} onClose={handleClose}>
                      <Box sx={{
                        p: 2,
                        bgcolor: '#3E1051',
                        width: '80%',
                        mx: 'auto',
                        mt: '10%',
                        position: 'relative',
                        pt: "50px",
                        borderRadius: "10px",
                      }}>
                        <IconButton
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#fff'
                          }}
                        >
                          <Close />
                        </IconButton>
                        <Typography
                          sx={{
                            fontFamily: "Libre Baskerville",
                            fontSize: "13px",
                            fontWeight: "700",
                            color: "#ED8131",
                            mb: '0.5rem',
                          }}
                        >
                          Estás a 3 pasos de
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Libre Baskerville",
                            fontWeight: '700',
                            fontSize: '18px',
                            color: '#EBA34F',
                            mb: '0.5rem',
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Inter',
                            fontWeight: '700',
                            fontSize: '18px',
                            color: '#fff',
                            mb: '0.5rem',
                          }}
                        >
                          {type}
                        </Typography>
                        <ul
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '18px',
                            color: '#fff',
                            listStyle: 'none',
                            paddingLeft: '0',
                          }}
                        >
                          {listItemCheck.map((item) => (
                            <li
                              key={item}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                lineHeight: '26.2px',
                                wordSpacing: '3px',
                              }}
                            >
                              <CheckRounded
                                sx={{ color: '#4CBB4A', fontSize: '16px', mt: '5px' }}
                              />
                              {item}
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </Modal>
                  </>
                ) : (
                  <ul
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#fff",
                      listStyle: "none",
                    }}
                  >
                    {listItemCheck.map((item) => (
                      <Stack direction="row" gap="5px">
                        <CheckRounded
                          sx={{ color: "#4CBB4A", fontSize: "16px", mt: "5px" }}
                        />
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            lineHeight: "26.2px",
                            wordSpacing: "3px",
                          }}
                        >
                          {item}
                        </li>
                      </Stack>
                    ))}
                  </ul>
                )}
            </Stack>

            <Divider sx={{ bgcolor: "#ffffff80" }} />
            <Box
              sx={{
                display: "flex",
                px: "2rem",
                justifyContent: "left",
                alignItems: "start",
                flexDirection: "column",
                mb: "25%",
                mt: "25%",
                gap: "2rem",
              }}
            >
              {stepsLogin.map((step) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "white",
                      width: "26px",
                      height: "26px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {step.isSelected === 1 ? (
                      <Box
                        sx={{
                          bgcolor: "#ED8131",
                          width: "16px",
                          height: "16px",
                          mx: "auto",
                          borderRadius: "50%",
                        }}
                      />
                    ) : step.isSelected === 0 ? (
                      <CheckCircleRounded
                        sx={{ fontSize: "19px", color: "#4CBB4A" }}
                      />
                    ) : null}
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Libre Baskerville",
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "22.8px",
                      color: step.isSelected === 2 ? "#fff9" : "#FFFFFF",
                      maxWidth: "100%",
                    }}
                  >
                    {step.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8.5 }}>
          <Box sx={{ backgroundColor: "#fafafa" }}>
            <Outlet />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}