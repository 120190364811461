import {
  Container,
  Box,
  FormLabel,
  Typography,
  TextField,
  Button,
  Snackbar,
} from '@mui/material';
import React, { useState } from 'react';
import Navbar from "../../components/shared/navbarSignup";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert } from "@mui/material";
import { sendingCode } from '../../services/userService';

const RecoverPassword = () => {

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {

    let valid = true;
    let newErrors = { email: "" };

    setErrorMessage(""); // Limpiar mensajes anteriores
    setSuccessMessage(""); // Limpiar mensajes anteriores

    if (!validateEmail(email)) {
      newErrors.email = "Formato de correo inválido";
      setErrorMessage("El correo electrónico no tiene un formato válido");
      setSnackbarType("error");
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      setOpenSnackbar(true);
      return;
    }
    const sendCode = {
      email
    }

    await sendingCode(sendCode)

    localStorage.setItem("email", email);
    setSuccessMessage("Correo enviado exitosamente");
    setSnackbarType("success");
    setOpenSnackbar(true);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Navbar />
      <div className="signup-login-container">
        <Container
          className="container-custom"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="box-signup"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: 2,
              p: "10px",
              position: "relative",
              height:
                errors.email
                  ? 413
                  : 413,
            }}
          >
            <a href="/login" style={{ textDecoration: 'none', position: 'absolute', left: 10, top: 10 }}>
              <ArrowBackIcon
                sx={{
                  color: "#3E1051",
                  fontSize: 40,
                  alignSelf: "flex-start",
                  cursor: "pointer",
                }}
              />
            </a>
            <Box
              sx={{
                height: "413px !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 170,
                }}
              >
                <FormLabel
                  sx={{
                    color: "#3E1051",
                    fontFamily: "Libre Baskerville",
                    fontWeight: "700",
                    fontSize: "28px",
                    lineHeight: "30.78px",
                    textAlign: "center",
                  }}
                >
                  {<b style={{ color: "#3E1051" }}>Recuperar contraseña</b>}
                </FormLabel>
                <FormLabel
                  sx={{
                    color: "#3E1051",
                    fontStyle: "Inter",
                    fontSize: "18px",
                    lineHeight: "30.78px",
                    textAlign: "center",
                    pt: "15px",
                  }}
                >
                  {<b style={{ color: "#747684", fontWeight: "200", }}>Enviaremos un link para reestablecer tu contraseña</b>}
                </FormLabel>
              </Box>
              <Box
                className={"container-input"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  maxWidth: 480,
                  pt: "10px",
                }}
              >
                <Typography className="text-2">Correo electrónico</Typography>
                <TextField
                  name="email"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  className="text-field"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  className="button-custom"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  Enviar correo
                </Button>
              </Box>
            </Box>
          </Box>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarType}
              sx={{ width: "100%" }}
            >
              {snackbarType === "success" ? successMessage : errorMessage}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </div>
  )
}

export default RecoverPassword;
