import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import authSlice from './slices/authSlice';
import freelancerPymeSlice from './slices/freelancerPymeSlice';


const store = configureStore({
  reducer: {
    user: userSlice,
    auth: authSlice,
    freelancerPyme: freelancerPymeSlice,
  },
});

export default store;
