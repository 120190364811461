import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../../styles/body.css";
import RatingStars from "../ratingStarts";
import { getFreelancerReviewByfreId } from "../../../../services/freelancerService";

export default function Review({ pyme, free }) {
  const [freelancerReview, setFreelancerReview] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        setLoading(true)
        const freeReview = await getFreelancerReviewByfreId(free.fre_id);
        setFreelancerReview(freeReview.data.data);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching industry list:", error);
        setLoading(false)
      }
    };

    fetchServiceList();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("es-ES", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box className="box-service">
        <Box className="box-service2">
          <Typography className="title">Reseñas</Typography>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress size={28} sx={{ color: "#eba34f" }} />
        </Box>
      ) : freelancerReview.length === 0 ? (
        <Box className="services-list">
          <Typography className="description">
            Usted todavía no posee reseñas. Una vez que reciba comentarios,
            aparecerán aquí.
          </Typography>
        </Box>
      ) : (
        freelancerReview.map((review) => (
          <Box key={review.frw_id} className="services-list">
            <Box className="box-iconos">
              <Box
                className="box-iconos2"
                sx={{
                  alignItems: "center",
                  width: isMobile ? "55%" : "auto",
                }}
              >
                <Typography
                  className="review"
                  sx={{
                    marginRight: isMobile ? "0%" : "1rem",
                  }}
                >
                  {review.pym_id.pym_company_name}
                </Typography>
                <RatingStars rating={review.frw_rating} size={16} />
              </Box>
              <Typography className="date">
                {formatDate(review.frw_date)}
              </Typography>
            </Box>
            <Typography className="description">
              {review.frw_description}
            </Typography>
          </Box>
        ))
      )}
    </>
  );
}
