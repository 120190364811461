import {
  Container,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  FormLabel,
  Grid2,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldCustom from "../../components/shared/TextField/TextFieldCustom.jsx";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { getSpecializationList } from "../../services/specializationService.js";
import { useNavigate } from "react-router-dom";
import { getIndustryList } from "../../services/pymeService.js";

export default function Specialization() {
  const navigate = useNavigate();

  //Specialization
  const [specialties, setSpecialties] = useState([]);

  //Industry
  const [industry, setIndustry] = useState([]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [customSpecialty, setCustomSpecialty] = useState("");
  const [loading, setLoading] = useState(true);

  let freelancerObject;
  let pymeObject;
  let isPymeObject;

  //LocalStorage
  const savedFreelancer = localStorage.getItem("freelancer");
  const isPymeOrFree = localStorage.getItem("isPyme");
  const savedPyme = localStorage.getItem("pyme");

  if (isPymeOrFree) {
    isPymeObject = JSON.parse(isPymeOrFree);
  }
  let isPyme;

  if (isPymeObject === "true") {
    isPyme = true;
  }

  if (isPymeObject === "false") {
    isPyme = false;
  }

  if (savedFreelancer) {
    freelancerObject = JSON.parse(savedFreelancer);
  }

  if (savedPyme) {
    pymeObject = JSON.parse(savedPyme);
  }

  // Cargar especialización o industria según el tipo de usuario
  useEffect(() => {
    const setSpecialtiesOrIndustry = async () => {
      if (!isPyme) {
        const newSpecialties = await getSpecializationList();
        setSpecialties(newSpecialties.data.data);
        setLoading(false);
      } else {
        const newIndustry = await getIndustryList();
        setIndustry(newIndustry.data.data);
        setLoading(false);
      }
    };

    setSpecialtiesOrIndustry();

    // Cargar IDs seleccionados desde localStorage
    if (isPyme && savedPyme) {
      setSelectedIds(pymeObject.industry || []);
    } else if (!isPyme && savedFreelancer) {
      setSelectedIds(freelancerObject.specialization || []);
    }
  }, [isPyme, savedFreelancer, savedPyme]);

  const handleSelect = (specialtyId) => {
    const currentIndex = selectedIds.indexOf(specialtyId);

    if (currentIndex === -1 && selectedIds.length < 3) {
      setSelectedIds([...selectedIds, specialtyId]);
    } else if (currentIndex !== -1) {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(currentIndex, 1);
      setSelectedIds(newSelectedIds);
    }
  };

  const handleVolver = () => {
    navigate(`/create-account/personal/?isPyme=${isPyme}`);
  };

  const handleContinue = () => {
    // Crear el objeto con los IDs seleccionados y la opción personalizada
    const selectedData = {
      selectedSpecialtyIds: selectedIds,
      customSpecialty: customSpecialty.trim() !== "" ? customSpecialty : null,
    };

    if (isPyme) {
      const newPyme = {
        ...pymeObject,
        industry: selectedData.selectedSpecialtyIds,
      };

      // Guardar en localStorage
      localStorage.setItem("pyme", JSON.stringify(newPyme));

      navigate(`/create-account/experience/pyme/?isPyme=${isPyme}`);
    } else {
      const newFreelancer = {
        ...freelancerObject,
        specialization: selectedData.selectedSpecialtyIds,
      };

      // Guardar en localStorage
      localStorage.setItem("freelancer", JSON.stringify(newFreelancer));

      navigate(`/create-account/experience/?isPyme=${isPyme}`);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
          width: "80%",
          flexDirection: "column",
        }}
      >
         {isPyme ? (
          <Grid2 
            container rowSpacing={5} 
            columnSpacing={2} 
            sx={{ pt: "5%" }}
          >
            <Grid2 size={{ xs: 12 }}>
              {/* Sector de la Industria  */}
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Sector de la Industria {<b style={{ color: "red" }}>*</b>}
              </FormLabel>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "21.78px",
                }}
              >
                Título Categoría
              </FormLabel>
              {industry.map((industrySector) => (
                <Box key={industrySector.ins_id}>
                  <FormControlLabel
                    sx={{
                      alignItems: 'flex-start',
                    }}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(industrySector.ins_id)}
                        onChange={() => handleSelect(industrySector.ins_id)}
                        sx={{
                          color: "#DBDCE5",
                          "&.Mui-checked": {
                            color: "#ED8131",
                          },
                          mt: '-8px',
                        }}
                        disabled={
                          !selectedIds.includes(industrySector.ins_id) &&
                          selectedIds.length >= 3
                        }
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1" sx={{ display: 'block' }}>
                          {industrySector.ins_name}
                        </Typography>
                        {industrySector.ins_description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ display: 'inline-block' }}
                          >
                            {industrySector.ins_description}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
              ))}
            </Grid2>
          </Grid2>
        ) : (
          <Grid2
            container
            rowSpacing={5}
            columnSpacing={2}
            sx={{ pt: "5%" }}
            flexDirection="column"
          >
            <Box>
              {/* Área de especialización */}
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Área de especialización {<b style={{ color: "red" }}>*</b>}
              </FormLabel>
              <Typography variant="body2" color="textSecondary">
                Asesoría que puedes ofrecer servicios a las pymes
              </Typography>
            </Box>
            <Box>
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "21.78px",
                }}
              >
                Título Categoría
              </FormLabel>
              {specialties.map((specialty) => (
                <Box key={specialty.spe_id}>
                  <FormControlLabel
                    sx={{
                      alignItems: 'flex-start',
                    }}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(specialty.spe_id)}
                        onChange={() => handleSelect(specialty.spe_id)}
                        sx={{
                          color: "#DBDCE5",
                          "&.Mui-checked": {
                            color: "#ED8131",
                          },
                          mt: '-8px',
                        }}
                        disabled={
                          !selectedIds.includes(specialty.spe_id) &&
                          selectedIds.length >= 3
                        }
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1" sx={{ display: 'block' }}>
                          {specialty.spe_name}
                        </Typography>
                        {specialty.spe_description && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ display: 'inline-block' }}
                          >
                            {specialty.spe_description}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
              ))}
            </Box>
            {/* <Box>
              <Grid2 size={{ xs: 12 }}>
                <TextFieldCustom
                  label={"Otra área de especialización"}
                  required={true}
                  nameTextField={"specialization"}
                  onChange={(e) => handleTextFieldChange(e.target.value)}
                  disabled={selectedIds.length >= 3 && customSpecialty === ""}
                />
              </Grid2>
            </Box> */}
          </Grid2>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pb: "5%",
          }}
        >
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "#e3602d",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: 3
            }}
            startIcon={<ArrowBackRounded />}
            onClick={handleVolver}
          >
            Volver
          </Button>
          <Button
            variant="filled"
            sx={{
              textTransform: "none",
              bgcolor: "#e3602d",
              color: "#ffffff",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: 3
            }}
            endIcon={<ArrowForwardRounded />}
            onClick={handleContinue}
            disabled={selectedIds.length < 1 && customSpecialty.trim() === ""}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
