import { Box, useMediaQuery, useTheme } from "@mui/material";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
// import contentfulConfig from "../../../../config/contentfulConfig";
import "../../../../styles/body.css";
import contentfulConfig from "../../../../config/contentfulConfig";

export default function ContentfulCustom() {
  const [publicity, setPublicity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Usar el hook de Material UI para detectar la vista móvil
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await contentfulConfig.getEntries({
          content_type: "advertisingDreUp",
        });
        setPublicity(response.items);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError("Error fetching page");
        setLoading(false);
      }
    };

    fetchAds();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      {isMobile ? (
        // Carrusel para vista móvil
        <Slider {...settings} >
          {publicity.map((section, index) => (
            <a
              key={index}
              href={section.fields.link}
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <Box sx={{ marginBottom: "2rem" }}>
                {section.fields.image && (
                  <img
                    src={section.fields.image.fields.file.url}
                    alt={section.fields.image.fields.title}
                    style={{
                      maxWidth: "100%",
                      borderRadius: 15,
                      height: "7rem",
                    }}
                  />
                )}
                <h2>
                  {section.fields.title}
                </h2>
                <p>
                  {section.fields.description}
                </p>
              </Box>
            </a>
          ))}
        </Slider>
      ) : (
        // Vista original para pantallas más grandes
        <Box style={{ width: "100%" }}>
          {publicity.map((section, index) => (
            <a
              key={index}
              href={section.fields.link}
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <Box sx={{ marginBottom: "2rem" }}>
                {section.fields.image && (
                  <img
                    src={section.fields.image.fields.file.url}
                    alt={section.fields.image.fields.title}
                    style={{
                      maxWidth: "100%",
                      borderRadius: 15,
                      height: "7rem",
                    }}
                  />
                )}
                <h2>{section.fields.title}</h2>
                <p>{section.fields.description}</p>
              </Box>
            </a>
          ))}
        </Box>
      )}
    </>
  );
}
