import React from 'react'
import Navbar from '../../components/shared/navbarSignup'
import CardConfirm from '../../components/shared/cards/cardConfirm'


const ConfirmEmail = () => {
  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh'
    }}
  >
    <Navbar />
    <div className="signup-login-container">
      <CardConfirm />
    </div>
  </div>
  )
}

export default ConfirmEmail