import { FormControl, FormLabel, TextField, Typography } from "@mui/material";
import React from "react";

export default function TextFieldCustom({
  fullWidth = true,
  label,
  required = false,
  disabled = false,
  nameTextField,
  variant = "outlined",
  value = "",
  type = "text",
  handleChange,
  errors,
  helperText,
  multiline = false,
  rows = 1,
  helperTopText,
  placeholder = "",
  showAsterisk = true,
  inputProps="",
  labelSx = {}
}) {
  return (
    <FormControl fullWidth={fullWidth}>
      <FormLabel
        sx={{
          color: "#3E1051",
          fontStyle: "Inter",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "21.78px",
          ...labelSx
        }}
      >
        {label} {required && showAsterisk && <b style={{ color: "red" }}>*</b>}
        {helperTopText && (
          <Typography sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16.94px",
            textAlign: "left",
            color: "#747684",
            marginBottom: 2,
            whiteSpace: "pre-line",
          }}>
            {helperTopText}
          </Typography>
        )}
      </FormLabel>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px", // Ajusta el valor según el nivel de redondez que desees
            backgroundColor: disabled && "#DBDCE5",
          },
        }}
        disabled={disabled}
        value={value}
        name={nameTextField}
        variant={variant}
        type={type}
        onChange={handleChange}
        error={errors}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        he
        placeholder={placeholder}
        inputProps={inputProps}
      />
    </FormControl>
  );
}