import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  Modal,
  FormControlLabel,
  Checkbox,
  Grid2,
  FormLabel,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../../styles/body.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextFieldCustom from "../../TextField/TextFieldCustom";
import {
  createFreelancerExperience,
  deleteFreelancerExperience,
  getFreelancerExperienceByfreId,
  updateFreelancerExperience,
} from "../../../../services/freelancerService";

export default function Experience({ pyme, free }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [freelancerExperience, setFreelancerExperience] = useState([]);
  const [selectedExperienceId, setSelectedExperienceId] = useState(null);

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        handleServiceList();
      } catch (error) {
        console.error("Error fetching industry list:", error);
      }
    };

    fetchServiceList();
  }, []);

  const handleServiceList = async () => {
    setLoading(true);
    const freeExperience = await getFreelancerExperienceByfreId(free.fre_id);
    setFreelancerExperience(freeExperience.data.data);
    setLoading(false);
  };

  const [newExperience, setNewExperience] = useState({
    fex_position: "",
    fex_company: "",
    fex_from_date: "",
    fex_to_date: "",
    fex_description: "",
    fre_id: free.fre_id,
  });

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [errors, setErrors] = useState({});

  const handleAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setNewExperience({
      fex_position: "",
      fex_company: "",
      fex_from_date: "",
      fex_to_date: "",
      fex_description: "",
      fre_id: free.fre_id,
    });
    setAccepted(false);
    setErrors({});
  };

  const handleDelete = async (experienceId) => {
    try {
      setLoading(true);
      await deleteFreelancerExperience(experienceId);
      setFreelancerExperience((prev) =>
        prev.filter((experience) => experience.fex_id !== experienceId)
      );
      setOpenDelete(false);
      setSuccessMessage("Experiencia eliminada exitosamente");
      setSnackbarType("success");
      setOpenSnackbar(true);
      handleServiceList();
      setLoading(false);
    } catch (error) {
      console.error("Error eliminando experiencia:", error);
      setErrorMessage("Error al eliminar una experiencia.");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const [accepted, setAccepted] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const styleAdd = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "75%" : "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "42%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewExperience((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newExperience.fex_position)
      newErrors.fex_position = "El cargo es obligatorio";
    if (!newExperience.fex_company)
      newErrors.fex_company = "La empresa es obligatoria";
    if (!newExperience.fex_from_date)
      newErrors.fex_from_date = "La fecha de desde es obligatoria";
    if (!accepted && !newExperience.fex_to_date)
      newErrors.fex_to_date = "La fecha de hasta es obligatoria";
    if (!newExperience.fex_description)
      newErrors.fex_description = "La descripción es obligatoria";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditClick = (experienceId) => {
    setLoading(true);
    const selectedExperience = freelancerExperience.find(
      (experience) => experience.fex_id === experienceId
    );

    setNewExperience({
      fex_position: selectedExperience.fex_position,
      fex_company: selectedExperience.fex_company,
      fex_from_date: selectedExperience.fex_from_date,
      fex_to_date: selectedExperience.fex_to_date,
      fex_description: selectedExperience.fex_description,
      fre_id: free.fre_id,
    });

    setEditingId(experienceId);
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);

    try {
      const experienceToSubmit = { ...newExperience };
      if (accepted) {
        delete experienceToSubmit.fex_to_date;
      }
      await createFreelancerExperience(experienceToSubmit);
      handleCloseAdd();
      handleServiceList();
      setLoading(false);
    } catch (error) {
      console.error("Error al crear experiencia:", error);
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("es-ES", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
  };

  const formatYear = (dateString) => {
    if (!dateString) {
      return "Presente";
    }
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const handleEditExperience = async () => {
    setLoading(true);
    if (!validateForm()) return;

    try {
      const experienceToSubmit = { ...newExperience };
      if (accepted) {
        await deleteFreelancerExperience(editingId);
        delete experienceToSubmit.fex_to_date;
        await createFreelancerExperience(experienceToSubmit);
        setFreelancerExperience((prev) =>
          prev.map((experience) =>
            experience.fex_id === editingId
              ? { ...experience, ...newExperience }
              : experience
          )
        );
        setEditingId(null);
        setNewExperience({
          fex_position: "",
          fex_company: "",
          fex_from_date: "",
          fex_to_date: "",
          fex_description: "",
          fre_id: free.fre_id,
        });
        handleServiceList();
      } else {
        await updateFreelancerExperience(editingId, experienceToSubmit);
        setFreelancerExperience((prev) =>
          prev.map((experience) =>
            experience.fex_id === editingId
              ? { ...experience, ...newExperience }
              : experience
          )
        );
        setEditingId(null);
        setNewExperience({
          fex_position: "",
          fex_company: "",
          fex_from_date: "",
          fex_to_date: "",
          fex_description: "",
          fre_id: free.fre_id,
        });
        handleServiceList();
      }
      setLoading(false);
    } catch (error) {
      console.error("Error actualizando experiencia:", error);
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setNewExperience({
      fex_position: "",
      fex_company: "",
      fex_from_date: "",
      fex_to_date: "",
      fex_description: "",
      fre_id: free.fre_id,
    });
    setAccepted(false);
    setErrors({});
  };

  useEffect(() => {
    if (newExperience.fex_to_date === null) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [newExperience.fex_to_date]);

  const formatDateEdit = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = `0${d.getUTCMonth() + 1}`.slice(-2);
    const day = `0${d.getUTCDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Box className="box-service" sx={{ mb: "40px" }}>
        <Box className="box-service2">
          <Typography className="title">Experiencia</Typography>
          <Button className="button-add" onClick={handleAdd}>
            Agregar experiencia
          </Button>
        </Box>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress size={28} sx={{ color: "#eba34f" }} />
          </Box>
        ) : (
          freelancerExperience.map((experience) =>
            editingId === experience.fex_id ? (
              <Box key={experience.fex_id} className="box-edit-experience">
                <Grid2 container rowSpacing={3} columnSpacing={2}>
                  <TextFieldCustom
                    label={"Cargo"}
                    nameTextField={"fex_position"}
                    required={true}
                    rows={5}
                    value={newExperience.fex_position}
                    handleChange={handleChange}
                    showAsterisk={false}
                    labelSx={{ mb: "7px" }}
                  />
                  <TextFieldCustom
                    label={"Empresa"}
                    nameTextField={"fex_company"}
                    required={true}
                    rows={5}
                    value={newExperience.fex_company}
                    handleChange={handleChange}
                    showAsterisk={false}
                    labelSx={{ mb: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    <TextFieldCustom
                      sx={{ flexGrow: 1, mr: 2 }}
                      label={"Desde"}
                      nameTextField={"fex_from_date"}
                      required={true}
                      rows={5}
                      value={formatDateEdit(newExperience.fex_from_date)}
                      handleChange={handleChange}
                      type="date"
                      showAsterisk={false}
                      labelSx={{ mb: "7px" }}
                    />
                    <TextFieldCustom
                      sx={{ flexGrow: 1, mr: 2 }}
                      label={"Hasta"}
                      nameTextField={"fex_to_date"}
                      required={true}
                      rows={5}
                      value={formatDateEdit(newExperience.fex_to_date)}
                      handleChange={handleChange}
                      type="date"
                      showAsterisk={false}
                      labelSx={{ mb: "7px" }}
                      disabled={accepted}
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={(e) => {
                          setAccepted(e.target.checked);
                        }}
                        sx={{
                          color: "#DBDCE5",
                          borderRadius: "4px",
                          "&.Mui-checked": {
                            color: "#E3602D",
                          },
                        }}
                      />
                    }
                    label={
                      <p style={{ fontFamily: "Inter" }}>
                        Aún me encuentro trabajando con esta empresa
                      </p>
                    }
                  />
                  <TextFieldCustom
                    label={"Descripción"}
                    nameTextField={"fex_description"}
                    required={true}
                    multiline={true}
                    rows={5}
                    value={newExperience.fex_description}
                    handleChange={handleChange}
                    showAsterisk={false}
                    labelSx={{ mb: "7px" }}
                  />
                </Grid2>
                <Box
                  className="modal-box-buttons"
                  sx={{ width: isMobile ? "60%" : "35%" }}
                >
                  <Button
                    className="modal-button button2"
                    onClick={handleEditExperience}
                  >
                    Guardar
                  </Button>
                  <Button
                    className="modal-button button1"
                    onClick={handleCancelEdit}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box key={experience.fex_id} className="services-list">
                <Box className="box-iconos">
                  <Typography
                    className="service"
                    sx={{ color: "#D43948 !important" }}
                  >
                    {experience.fex_position}
                  </Typography>
                  <Box
                    className="box-iconos2"
                    sx={{ width: isMobile ? "20%" : "10%" }}
                  >
                    <Button
                      style={{
                        background: "none",
                        border: "none",
                        padding: "3px",
                        minWidth: "unset",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEditClick(experience.fex_id)}
                    >
                      <EditOutlinedIcon className="edit-icon" />
                    </Button>
                    <Button
                      style={{
                        background: "none",
                        border: "none",
                        padding: "3px",
                        minWidth: "unset",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(experience.fex_id)}
                    >
                      <DeleteOutlineOutlinedIcon className="delete-icon" />
                    </Button>
                  </Box>
                </Box>
                <Box className="box-iconos" sx={{ width: "auto" }}>
                  <Typography className="subtitle">
                    {experience.fex_company}
                  </Typography>
                  <Typography className="date" sx={{ marginLeft: 5 }}>
                    {formatDate(experience.fex_from_date)} -{" "}
                    {formatYear(experience.fex_to_date)}
                  </Typography>
                </Box>
                <Typography className="description">
                  {experience.fex_description}
                </Typography>
              </Box>
            )
          )
        )}
      </Box>

      <Box>
        {/* Modal Add experience */}
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(62, 16, 81, 0.8)",
            },
          }}
        >
          <Box sx={styleAdd}>
            <Box>
              <Typography
                id="modal-add"
                className="modal-add-title"
                sx={{ mb: "0px !important" }}
              >
                Agregar nueva experiencia
              </Typography>
              <Grid2
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{ pt: "5%" }}
              >
                <TextFieldCustom
                  label={"Cargo"}
                  nameTextField={"fex_position"}
                  required={true}
                  rows={5}
                  value={newExperience.fex_position}
                  handleChange={handleChange}
                  showAsterisk={false}
                  labelSx={{ mb: "7px" }}
                  errors={!!errors.fex_position}
                  helperText={errors.fex_position}
                />
                <TextFieldCustom
                  label={"Empresa"}
                  nameTextField={"fex_company"}
                  required={true}
                  rows={5}
                  value={newExperience.fex_company}
                  handleChange={handleChange}
                  showAsterisk={false}
                  labelSx={{ mb: "7px" }}
                  errors={!!errors.fex_company}
                  helperText={errors.fex_company}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextFieldCustom
                    sx={{ flexGrow: 1, mr: 2 }}
                    label={"Desde"}
                    nameTextField={"fex_from_date"}
                    required={true}
                    rows={5}
                    value={newExperience.fex_from_date}
                    handleChange={handleChange}
                    type="date"
                    showAsterisk={false}
                    errors={!!errors.fex_from_date}
                    helperText={errors.fex_from_date}
                  />
                  <TextFieldCustom
                    sx={{ flexGrow: 1 }}
                    label={"Hasta"}
                    nameTextField={"fex_to_date"}
                    required={true}
                    rows={5}
                    value={newExperience.fex_to_date}
                    type="date"
                    handleChange={handleChange}
                    showAsterisk={false}
                    errors={!!errors.fex_to_date}
                    helperText={errors.fex_to_date}
                    disabled={accepted}
                  />
                </Box>
                <FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={(e) => {
                          setAccepted(e.target.checked);
                        }}
                        sx={{
                          color: "#DBDCE5",
                          borderRadius: "4px",
                          "&.Mui-checked": {
                            color: "#E3602D",
                          },
                        }}
                      />
                    }
                    label={
                      <p style={{ fontFamily: "Inter" }}>
                        Aún me encuentro trabajando con esta empresa
                      </p>
                    }
                  />
                </FormLabel>
                <TextFieldCustom
                  label={"Descripción"}
                  nameTextField={"fex_description"}
                  required={true}
                  multiline={true}
                  rows={5}
                  value={newExperience.fex_description}
                  handleChange={handleChange}
                  showAsterisk={false}
                  labelSx={{ mb: "7px" }}
                  errors={!!errors.fex_description}
                  helperText={errors.fex_description}
                />
              </Grid2>
            </Box>
            <Box className="modal-box-buttons">
              <Button className="modal-button button1" onClick={handleCloseAdd}>
                Cancelar
              </Button>
              <Button className="modal-button button2" onClick={handleSubmit}>
                Guardar
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Modal Delete experience */}
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(62, 16, 81, 0.8)",
            },
          }}
        >
          <Box sx={styleDelete}>
            <Box>
              <Typography
                id="modal-add"
                className="modal-add-title"
                sx={{ fontSize: "16px !important" }}
              >
                Eliminar experiencia
              </Typography>
              <FormLabel
                className="modal-add-subtitle"
                sx={{ color: "#747684 !important" }}
              >
                ¿Seguro que deseas eliminar esta experiencia?
              </FormLabel>
              <Box className="modal-box-buttons">
                <Button
                  className="modal-button button1"
                  onClick={handleCloseDelete}
                >
                  Cancelar
                </Button>
                <Button
                  className="modal-button button2"
                  onClick={() => handleDelete(selectedExperienceId)}
                >
                  Si, eliminar experiencia
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarType === "success" ? successMessage : errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
