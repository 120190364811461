import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { emailForwardingById } from '../../../services/userService.js'
import { Alert,  Snackbar} from "@mui/material";

const CardRegistration = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [snackbarType, setSnackbarType] = useState("success");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { id } = useSelector((state) => state.user);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  

  const handleSend = async () => {
    try {
      await emailForwardingById(id)
      setSuccessMessage("Correo enviado satisfactoriamente");
      setSnackbarType("success");
      setOpenSnackbar(true);
    } catch (error) {
      setErrorMessage("Error al enviar el correo electrónico. Intente mas tarde");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  }


  return (
    <Card className="cardRegistration" sx={{ minWidth: 275 }}>
      <CardContent className="card-text-registration">
        <Typography className="text-registration" component="div">
          Hemos enviado un enlace a tu correo electrónico, <br />{" "}
          <span>
            por favor haz clic en el enlace para continuar con tu proceso de
            registro
          </span>
        </Typography>
      </CardContent>
      <CardActions className="card-text-registration-2">
        <Typography className="text-registration-2">
          No he recibido el mensaje
        </Typography>
        <Link onClick={handleSend} className="link-registration">Volver a enviar el mensaje</Link>
      </CardActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CardRegistration;
