import {
  Container,
  Box,
  FormLabel,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import Navbar from "../../components/shared/navbarSignup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { changePassword, codeActivation } from "../../services/userService";

function NewPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code"); // Aquí capturas el parámetro "code"
  const id = queryParams.get("id"); // Aquí capturas el parámetro "code"

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    let valid = true;
    let newErrors = { newPassword: "", confirmPassword: "" };

    setErrorMessage(""); // Limpiar mensajes anteriores
    setSuccessMessage(""); // Limpiar mensajes anteriores

    try {
      const usrID = Number(id)
  
      const data = {
        code: Number(code),
      };
      await codeActivation(usrID, data);
    } catch (error) {
      setErrorMessage("Error al validar el correo");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }

    if (!validatePassword(formData.newPassword)) {
      newErrors.newPassword =
        "La contraseña debe tener al menos 8 caracteres, un carácter especial, un número, una mayúscula y una minúscula";
      setErrorMessage("La contraseña no cumple con los requisitos");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Las contraseñas no coinciden";
      setErrorMessage("Las contraseñas no coinciden");
      setSnackbarType("error");
      setOpenSnackbar(true);
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    try {
      const usrID = Number(id)
      const password = {
        newPassword: formData.newPassword,
        newPasswordRepeat: formData.confirmPassword,
      };

      await changePassword(usrID, password);
      navigate("/login");
    } catch (error) {
      setErrorMessage("Error al cambiar la contraseña. Intente mas tarde");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <div className="signup-login-container">
        <Container
          className="container-custom"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="box-signup"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: 3,
              height: errors.newPassword || errors.confirmPassword ? 520 : 480,
            }}
          >
            <Box className={"box-container"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 130,
                }}
              >
                <FormLabel
                  sx={{
                    color: "#3E1051",
                    fontFamily: "Libre Baskerville",
                    fontWeight: "700",
                    fontSize: "28px",
                    lineHeight: "30.78px",
                    textAlign: "center",
                  }}
                >
                  {<b style={{ color: "#3E1051" }}>Recuperar contraseña</b>}
                </FormLabel>
                <FormLabel
                  sx={{
                    color: "#DBDCE5",
                    fontStyle: "Inter",
                    fontSize: "18px",
                    fontWeight: "100",
                    lineHeight: "30.78px",
                    textAlign: "center",
                    pt: "15px",
                  }}
                >
                  Por favor escribe tu nueva contraseña
                </FormLabel>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  maxWidth: 480,
                }}
              >
                <Typography className="text-2">Nueva Contraseña</Typography>
                <TextField
                  name="newPassword"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  className="text-field"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  value={formData.newPassword}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Typography className="text-2">Repite la contraseña</Typography>
                <TextField
                  name="confirmPassword"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  className="text-field"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Button
                  className="button-custom"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  Guardar e iniciar sesion
                </Button>
              </Box>
            </Box>
          </Box>
          {/* Alerta flotante */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarType}
              sx={{ width: "100%" }}
            >
              {snackbarType === "success" ? successMessage : errorMessage}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </div>
  );
}

export default NewPassword;
