import React from "react";
import { Navigate } from "react-router-dom";

// Función para verificar si el usuario tiene un token válido
const isAuthenticated = () => {
  const token = localStorage.getItem("token"); // Asumimos que guardas el token en localStorage
  return !!token; // Retorna true si existe un token, false si no
};

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
