import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import Body from "./body";
import { Box, CircularProgress } from "@mui/material";
import { getFreelancerReviewAVGRating } from "../../../services/freelancerService";

export default function HomeCustom({ isHome, isPyme }) {
  const [pyme, setPyme] = useState(null);
  const [free, setFree] = useState(null);
  const [ionicialRating, setIonicialRating] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let savedPyme = null;
      let savedFree = null;

      if (isPyme) {
        savedPyme = localStorage.getItem("pyme");
      } else {
        savedFree = localStorage.getItem("freelancer");
      }

      if (savedPyme) {
        try {
          const parsedPyme = JSON.parse(savedPyme);
          setPyme(parsedPyme);
        } catch (error) {
          console.error("Error parsing savedPyme:", error);
        }
      }

      if (savedFree) {
        try {
          const parsedFree = JSON.parse(savedFree);
          setFree(parsedFree);
          const freeReting = await getFreelancerReviewAVGRating(
            parsedFree.fre_id
          );
          setIonicialRating(freeReting.data.data);
        } catch (error) {
          console.error("Error parsing savedFree:", error);
        }
      }
    };

    fetchData();
  }, [isPyme]);

  const imageProfile = isPyme
    ? pyme?.pym_photo
    : free?.fre_photo || "/static/img/profile-sin-img.png";
  const name = isPyme ? pyme?.pym_full_name : free?.fre_full_name || "User";
  const description = isPyme
    ? pyme?.pym_description
    : free?.fre_description ||
      "Debe agregar una descripción editando su perfil";

  const verifications = [
    { id: 1, label: "Profesional", approved: free?.fre_full_name ? 1 : 0 },
    { id: 2, label: "Identidad", approved: free?.fre_rut ? 1 : 0 },
    { id: 3, label: "Pago", approved: 1 },
    { id: 4, label: "Teléfono", approved: free?.fre_phone ? 1 : 0 },
    { id: 5, label: "Email", approved: free?.usr_id?.usr_email ? 1 : 0 },
    {
      id: 6,
      label: "Redes",
      approved: free?.usr_id?.socialMedia?.length > 0 ? 1 : 0,
    },
  ];
  return (
    <>
      <Navbar isPyme={isPyme} />
      {pyme || free ? (
        <Body
          isHome={isHome}
          isPyme={isPyme}
          image_profile={imageProfile}
          rating={ionicialRating}
          name={name}
          description={description}
          verifications={verifications}
          pyme={pyme}
          free={free}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            marginTop: "64px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Outlet />
    </>
  );
}
