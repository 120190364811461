import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormLabel,
  Grid2,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldCustom from "../../components/shared/TextField/TextFieldCustom.jsx";
import BtnCustom from "../../components/shared/Btns/btnCustom.jsx";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import SelectCustom from "../../components/shared/Select/SelectCustom.jsx";
import {
  createPymer,
  getCompanyAgeList,
  getLocationPymeList,
  getTypeCompanyList,
} from "../../services/pymeService.js";
import { createSocialMedia, loginGoogleFacebook } from "../../services/userService.js";
import { useNavigate } from "react-router-dom";

export default function ExperiencePyme() {
  const navigate = useNavigate();
  const [typeCompany, setTypeCompany] = useState([]);
  const [companyAge, setCompanyAge] = useState([]);
  const [locationPyme, setLocationPyme] = useState([]);

  const [selectedIdTypeCompany, setSelectedIdTypeCompany] = useState();
  const [selectedIdCompanyAge, setSelectedIdCompanyAge] = useState();
  const [selectedIdLocationPyme, setSelectedIdLocationPyme] = useState();

  const [pymWhyJoin, setPymWhyJoin] = useState("");
  const [pymDescription, setPymDescription] = useState("");

  const [loading, setLoading] = useState(true);

  const [socialMedia, setSocialMedia] = useState([
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
  ]);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);

  let pymeObject;
  let userObject;

  //LocalStorage
  const savedPyme = localStorage.getItem("pyme");
  const savedUser = localStorage.getItem("user");

  if (savedPyme) {
    pymeObject = JSON.parse(savedPyme);
  }

  if (savedUser) {
    userObject = JSON.parse(savedUser);
  }

  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index][field] = value;
    setSocialMedia(updatedSocialMedia);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const setSelectPyme = async () => {
      const newTypeCompany = await getTypeCompanyList();
      const newCompanyAge = await getCompanyAgeList();
      const newLocationPyme = await getLocationPymeList();
      setTypeCompany(newTypeCompany.data.data);
      setCompanyAge(newCompanyAge.data.data);
      setLocationPyme(newLocationPyme.data.data);
      setLoading(false);
    };

    setSelectPyme();
  }, []);

  const handleTypeCompanyChange = (event) => {
    setSelectedIdTypeCompany(event.target.value);
  };

  const handleCompanyAgeChange = (event) => {
    setSelectedIdCompanyAge(event.target.value);
  };

  const handleLocationPymeChange = (event) => {
    setSelectedIdLocationPyme(event.target.value);
  };

  const handleVolver = () => {
    navigate(`/create-account/specialization/?isPyme=true`);
  };

  // Función para validar URLs
  const validateURL = (url) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  const handleContinue = async () => {
    setLoadingFinish(true)
    let newErrors = {};

    // Validación de campos de texto
    if (!pymWhyJoin) newErrors.pymWhyJoin = "Este campo es requerido";
    if (!pymDescription) newErrors.pymDescription = "Este campo es requerido";

    // Validación de los selects
    if (!selectedIdTypeCompany)
      newErrors.selectedIdTypeCompany = "Debes seleccionar un tipo de empresa";
    if (!selectedIdCompanyAge)
      newErrors.selectedIdCompanyAge =
        "Debes seleccionar una antigüedad de la empresa";
    if (!selectedIdLocationPyme)
      newErrors.selectedIdLocationPyme = "Debes seleccionar una ubicación";

    // Validación de aceptación de términos y condiciones
    if (!termsAccepted)
      newErrors.termsAccepted = "Debes aceptar los términos y condiciones";

    // Validar que al menos una red social tenga nombre y URL
    const selectedSocialMedia = socialMedia.filter(
      (media) => media.som_name !== "" && media.som_url !== ""
    );
    if (selectedSocialMedia.length === 0) {
      newErrors.socialMedia =
        "* Debes seleccionar al menos una red social con su respectivo enlace";
    }

    // Validación de URLs de redes sociales usando validateURL
    socialMedia.forEach((media, index) => {
      if (media.som_url && !validateURL(media.som_url)) {
        newErrors.socialMedia = `La URL en la red social "${media.som_url}" no es válida`;
      }
    });

    // Si hay errores, actualiza el estado de errores
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const finalRut = pymeObject.pym_rut + pymeObject.rut; 
      const newPyme = {
        pym_company_name: pymeObject.pym_company_name,
        pym_full_name: pymeObject.pym_full_name,
        pym_phone: pymeObject.pym_phone,
        pym_rut: finalRut,
        pym_company_position: pymeObject.pym_company_position,
        pym_why_join: pymWhyJoin,
        pym_description: pymDescription,
        coa_id: selectedIdCompanyAge,
        lop_id: selectedIdLocationPyme,
        tyc_id: selectedIdTypeCompany,
        usr_id: userObject.id,
        pla_id: pymeObject.pla_id,
        industry: pymeObject.industry,
      };
      try {
        await createPymer(newPyme);
        // Recorre las redes sociales y llama a createSocialMedia si los campos no están vacíos
        await Promise.all(
          socialMedia.map(async (media) => {
            if (media.som_name && media.som_url) {
              const socialMediaData = {
                som_name: media.som_name,
                som_url: media.som_url,
                usr_id: userObject.id,
              };
              await createSocialMedia(socialMediaData);
            }
          })
        );
        setSuccessMessage("Pyme registrada exitosamente");
        setSnackbarType("success");
        setOpenSnackbar(true);
        try {
          const responseLogin = await loginGoogleFacebook(userObject.email);
          const dataUser = {
            email: userObject.email,
            id: responseLogin.data.usr_id,
            type: "",
            isCreate: responseLogin.data.isCreate,
          };
          const userLogin = {
            usr_email: userObject.email,
            usr_id: responseLogin.data.usr_id,
            access_token: responseLogin.data.access_token,
            refreshToken: responseLogin.data.refreshToken,
            usr_type: responseLogin.data.usr_type,
            isCreate: responseLogin.data.isCreate,
          };

          localStorage.setItem("user-login", JSON.stringify(userLogin));
          localStorage.setItem("user", JSON.stringify(dataUser));
          navigate(`/home`);
          setLoadingFinish(false)
        } catch (error) {
          setErrorMessage("Error al iniciar sesión.");
          setSnackbarType("error");
          setOpenSnackbar(true);
          navigate(`/login`);
          setLoadingFinish(false)
        }
      } catch (error) {
        setErrorMessage("Error al crear el freelancer verifique los datos.");
        setSnackbarType("error");
        setOpenSnackbar(true);
        setLoadingFinish(false)
      }
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
          width: "80%",
          flexDirection: "column",
        }}
      >
        <Grid2
          container
          rowSpacing={5}
          columnSpacing={2}
          sx={{ pt: "5%", pb: "5%" }}
        >
          {/* Experiencia profesional */}

          <Grid2 size={{ xs: 12 }}>
            <SelectCustom
              label={"Tipo de empresa"}
              nameTextField={"type_company"}
              required={true}
              options={typeCompany.map((type) => ({
                value: type.tyc_id,
                label: type.tyc_name,
              }))}
              value={selectedIdTypeCompany} // Aquí va el valor seleccionado
              handleChange={handleTypeCompanyChange}
            />
            {errors.selectedIdTypeCompany && (
              <Typography color="error">
                {errors.selectedIdTypeCompany}
              </Typography>
            )}
          </Grid2>

          {/* Pregunta freelance */}
          <Grid2 size={{ xs: 12 }}>
            <SelectCustom
              label={"Ubicación"}
              nameTextField={"location_pyme"}
              required={true}
              options={locationPyme.map((location) => ({
                value: location.lop_id,
                label: location.lop_name,
              }))}
              value={selectedIdLocationPyme} // Aquí va el valor seleccionado
              handleChange={handleLocationPymeChange}
            />
            {errors.selectedIdLocationPyme && (
              <Typography color="error">
                {errors.selectedIdLocationPyme}
              </Typography>
            )}
          </Grid2>
          {/* Pregunta freelance */}
          <Grid2 size={{ xs: 12 }}>
            <SelectCustom
              label={"Antigüedad de la empresa"}
              nameTextField={"company_age"}
              required={true}
              options={companyAge.map((age) => ({
                value: age.coa_id,
                label: age.coa_name,
              }))}
              value={selectedIdCompanyAge} // Aquí va el valor seleccionado
              handleChange={handleCompanyAgeChange}
            />
            {errors.selectedIdCompanyAge && (
              <Typography color="error">
                {errors.selectedIdCompanyAge}
              </Typography>
            )}
          </Grid2>
          {/* Pregunta freelance */}
          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={
                "¿Para qué te gustaría suscribirte en  Dreup y conectar con expertos y participar de los eventos?"
              }
              nameTextField={"pym_why_join"}
              required={true}
              value={pymWhyJoin}
              handleChange={(e) => setPymWhyJoin(e.target.value)}
              errors={!!errors.pymWhyJoin}
              helperText={errors.pymWhyJoin}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={
                "Describe brevemente los principales objetivos y desafíos que enfrenta tu empresa en este momento"
              }
              nameTextField={"pym_description"}
              required={true}
              value={pymDescription}
              handleChange={(e) => setPymDescription(e.target.value)}
              errors={!!errors.pymDescription}
              helperText={errors.pymDescription}
            />
          </Grid2>
          {/* Redes sociales */}
          <Grid2 size={{ xs: 12 }} sx={{ width: "100%", mt: "5% !important" }}>
            <Grid2 size={{ xs: 12 }}>
              {/* Enlace a tu Portafolio */}
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Enlace a tu Portafolio ({" "}
                {
                  <b style={{ color: "#ED8131" }}>
                    {" "}
                    Linkedin, Facebook, Instagram, GitHub, Gitlab, Web, Canvas,
                    Youtube, Pinteres.
                  </b>
                }{" "}
                ) {<b style={{ color: "red" }}>*</b>}
              </FormLabel>
            </Grid2>
            <Grid2>
              <FormLabel
                sx={{
                  color: "#747684",
                  fontStyle: "Inter",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21.78px",
                }}
              >
                Escribe tus 5 redes principales
              </FormLabel>
              {socialMedia.map((item, index) => (
                <Grid2
                  container
                  direction="row"
                  rowSpacing={1}
                  columnSpacing={3}
                  key={index}
                >
                  <Grid2 sx={{ width: { xs: "100%", md: "35%" }, mt: "5px" }}>
                    <SelectCustom
                      showAsterisk={false}
                      required
                      value={item.som_name}
                      nameSelect="som_name"
                      options={[
                        { value: "Linkedin", label: "Linkedin" },
                        { value: "Facebook", label: "Facebook" },
                        { value: "Instagram", label: "Instagram" },
                        { value: "GitHub", label: "GitHub" },
                        { value: "Gitlab", label: "Gitlab" },
                        { value: "Web", label: "Web" },
                        { value: "Canvas", label: "Canvas" },
                        { value: "Youtube", label: "Youtube" },
                        { value: "Pinteres", label: "Pinteres" },
                      ]}
                      handleChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "som_name",
                          e.target.value
                        )
                      }
                    />
                  </Grid2>
                  <Grid2 sx={{ width: { xs: "100%", md: "61%" }, mt: "5px" }}>
                    <TextFieldCustom
                      required={true}
                      showAsterisk={false}
                      nameTextField="som_url"
                      value={item.som_url}
                      handleChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "som_url",
                          e.target.value
                        )
                      }
                    />
                  </Grid2>
                </Grid2>
              ))}
              {errors.socialMedia && (
                <Typography color="error">{errors.socialMedia}</Typography>
              )}
            </Grid2>
          </Grid2>
          {/* TyC */}
          <Grid2 size={{ xs: 12 }}>
            <FormControlLabel
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              control={
                <Checkbox
                  sx={{
                    color: "#C94E1F",
                    "&.Mui-checked": {
                      color: "#E3602D",
                    },
                  }}
                />
              }
              label={
                <Link
                  href="https://dreup.cl"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  style={{ fontFamily: "Inter",  color: "#3E1051" }}
                >
                  Acepto los términos y condiciones{" "}
                  <b style={{ color: "red" }}>*</b>
                </Link>
              }
            />
          </Grid2>
        </Grid2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pb: "5%",
          }}
        >
          <BtnCustom
            startIcon={<ArrowBackRounded />}
            label="Volver"
            variant={"text"}
            onClick={handleVolver}
          />
          <BtnCustom
            endIcon={<ArrowForwardRounded />}
            label={loadingFinish ? (
              <CircularProgress
                size={24}
                sx={{ color: "#747684", marginRight: 1 }}
              />
            ) : (
              "Continuar"
            )}
            onClick={handleContinue}
          />
        </Box>
      </Box>
      {/* Alerta flotante */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
