// src/components/FileUpload.js
import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../config/firebaseConfig";
import { createPayment } from "../services/paymentService";

const FileUpload = () => {
  // const [file, setFile] = useState(null);          // Estado para almacenar el archivo seleccionado
  // const [loading, setLoading] = useState(false);   // Estado de carga
  // const [fileUrl, setFileUrl] = useState('');      // Estado para almacenar la URL del archivo subido

  // // Función para manejar el archivo seleccionado
  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  // // Función para manejar la carga del archivo a Firebase
  // const handleUpload = () => {
  //   if (!file) return;

  //   setLoading(true);
  //   const storageRef = ref(storage, `uploads/${file.name}`);  // Crea la referencia en Firebase Storage
  //   const uploadTask = uploadBytesResumable(storageRef, file); // Carga el archivo

  //   // Monitorea el progreso de la carga
  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       // Progreso de la carga
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log(`Upload is ${progress}% done`);
  //     },
  //     (error) => {
  //       // Manejo de errores
  //       console.error("Error uploading file:", error);
  //       setLoading(false);
  //     },
  //     () => {
  //       // Obtén la URL del archivo subido
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         setFileUrl(downloadURL);  // Guarda la URL en el estado
  //         setLoading(false);
  //         console.log("File available at", downloadURL);
  //       });
  //     }
  //   );
  // };

  const [amount, setAmount] = useState();

  const handlePayment = async () => {
    try {
      const data = {
        sessionId: `session-${Date.now()}`, // Generar un sessionId dinámico
        buyOrder: `order-${Date.now()}`, // Generar un buyOrder dinámico
        amount: amount, // Monto que ingresa el usuario
        returnUrl: "http://localhost:3000/transbank/success", // URL de retorno
      };
      const response = await createPayment(data);

      // Redireccionar a la URL de pago de Transbank con el token_ws
      if (response.url && response.token) {
        window.location.href = `${response.url}?token_ws=${response.token}`;
      } else {
        console.error("Error: No se recibió la URL de Transbank");
      }
    } catch (error) {
      console.error("Error al iniciar la transacción", error);
    }
  };

  return (
    // <div>
    //   <Typography variant="h6">Cargar imagen o documento</Typography>
    //   <input type="file" onChange={handleFileChange} />
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     onClick={handleUpload}
    //     disabled={loading}
    //   >
    //     {loading ? <CircularProgress size={24} /> : 'Subir'}
    //   </Button>

    //   {fileUrl && (
    //     <Typography variant="body1" style={{ marginTop: 20 }}>
    //       Archivo subido correctamente: <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>
    //     </Typography>
    //   )}
    // </div>
    <div>
      <h1>Pagar</h1>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(Number(e.target.value))}
        placeholder="Monto"
      />
      <button onClick={handlePayment}>Pagar</button>
    </div>
  );
};

export default FileUpload;
