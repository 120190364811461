import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardSelectType = () => {
  const navigate = useNavigate();

  const handleConfirmFree = () => {
    navigate("/create-account/personal/?isPyme=false");
  }

  const handleConfirmPyme = () => {
    navigate("/plans");
  }
  return (
    <Box
      className="div-type-user"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        className="card-type-user"
        sx={{ position: "relative", overflow: "visible" }}
      >
        <Box sx={{ position: "relative", height: 305 }}>
          <CardMedia
            sx={{
              position: "absolute",
              top: -150,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              height: 305,
              zIndex: 1,
              objectFit: "cover", // Asegura que la imagen se ajuste sin deformarse
            }}
            component="img"
            image="/static/img/free.png"
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 50%, rgba(255,255,255,1))",
              zIndex: 2, // Este asegura que el gradiente quede encima de la imagen
            }}
          />
        </Box>
        <CardContent className="box-text-type-user">
          <Typography className="title-type-user">¿Eres freelance?</Typography>
          <Typography className="text-type-user">
            Postula y se parte de Dreup
          </Typography>
          <Typography className="text-type-user-2">
            Incrementa tus ingresos y Ayuda a las PYMES a Crecer
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            className="btn-type-user"
            sx={{
              textTransform: "none",
            }}
            onClick={handleConfirmFree}
          >
            Postularme <ArrowForwardIcon />
          </Button>
        </CardActions>
      </Card>

      <Card
        className="card-type-user"
        sx={{ position: "relative", overflow: "visible", marginBottom: {sm: 0, xs: 10} }}
      >
        <Box sx={{ position: "relative", height: 305 }}>
          <CardMedia
            sx={{
              position: "absolute",
              top: -150,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              height: 305,
              zIndex: 1,
              objectFit: "cover", // Asegura que la imagen se ajuste sin deformarse
            }}
            component="img"
            image="/static/img/pyme.png"
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 50%, rgba(255,255,255,1))",
              zIndex: 2, // Este asegura que el gradiente quede encima de la imagen
            }}
          />
        </Box>
        <CardContent className="box-text-type-user">
          <Typography className="title-type-user">¿Eres una PYME?</Typography>
          <Typography className="text-type-user">Potencia tu Pyme</Typography>
          <Typography className="text-type-user-2">
            Accede a Expertos en Gestión, Ventas y Posicionamiento en el Mercado
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            className="btn-type-user"
            sx={{
              textTransform: "none",
            }}
            onClick={handleConfirmPyme}
          >
            Potenciar mi Pyme <ArrowForwardIcon />
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CardSelectType;
