import React, { useEffect, useState } from 'react';
import HomeCustom from '../../components/shared/account/homeCustom';
import { Box, CircularProgress } from '@mui/material';
import { getPymeByUsrId } from '../../services/pymeService';
import { getOneFreelancerbyID } from '../../services/freelancerService';

const Freelancer = () => {
  const [isLoginbject, setIsLoginbject] = useState(null);
  const [isPyme, setIsPyme] = useState(null);
  const [pyme, setPyme] = useState(null);
  const [free, setFree] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadUserLogin = () => {
    const savedLogin = localStorage.getItem("user-login");
    if (savedLogin) {
      const parsedLogin = JSON.parse(savedLogin);
      setIsLoginbject(parsedLogin);
      setIsPyme(parsedLogin.usr_type === "pyme");
      setIsLoading(false);  
    } else {
      console.error("No se encontró 'user-login' en localStorage");
      setTimeout(loadUserLogin, 500);
    }
  };


  useEffect(() => {
    loadUserLogin();
  }, []);

  useEffect(() => {
    if (isLoginbject && isPyme !== null) {
      const getInfo = async () => {
        try {
          if (isPyme) {
            const infoPyme = await getPymeByUsrId(isLoginbject.usr_id);
            setPyme(infoPyme.data.data);
            localStorage.setItem("pyme", JSON.stringify(infoPyme.data.data));
          } else {
            const infoFree = await getOneFreelancerbyID(isLoginbject.usr_id);
            setFree(infoFree.data.data);
            localStorage.setItem("freelancer", JSON.stringify(infoFree.data.data));
          }
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };
      getInfo();
    }
  }, [isLoginbject, isPyme]);


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isLoginbject === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isPyme === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if ((isPyme && !pyme) || (!isPyme && !free)) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <HomeCustom isHome={false} isPyme={isPyme} />
  )
}

export default Freelancer;