import React from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line
import CssBaseline from '@mui/material/CssBaseline';  // Proporciona un reinicio de CSS estándar
import theme from './theme/theme';

// Importa el tema que creamos // Tus rutas

const App = () => {
  return (
    // <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
  //  </ThemeProvider>
  );
};

export default App;
