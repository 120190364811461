import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldCustom from "../../components/shared/TextField/TextFieldCustom.jsx";
import RadioCustom from "../../components/shared/RadioCustom/RadioCustom.jsx";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerPyme } from "../../store/slices/freelancerPymeSlice.js";
import { useNavigate } from "react-router-dom";

const companyCharges = [
  { name: "ceo", label: "Director General / CEO" },
  { name: "cco", label: "Director Comercial / Ventas" },
  { name: "rrhh", label: "Gerente de Recursos Humanos" },
  { name: "coo", label: "Gerente de Operaciones" },
  { name: "cdb", label: "Gerente de Desarrollo de Negocios" },
  { name: "otro", label: "Otro" },
];

const ageRanges = [
  { name: "25-32", label: "25-32" },
  { name: "33-40", label: "33-40" },
  { name: "41-48", label: "41-48" },
  { name: "49-56", label: "49-56" },
  { name: "57-64", label: "57-64" },
];

export default function PersonalData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fre_full_name: "",
    fre_phone: "",
    fre_rut: null,
    fre_age_range: "",
    rut: null,
  });

  const [formDataPyme, setFormDataPyme] = useState({
    pym_company_name: "",
    pym_company_position: "",
    pym_full_name: "",
    pym_phone: "",
    pym_rut: null,
    rut: null,
  });

  const [companyName, setCompanyName] = useState("");

  const [errors, setErrors] = useState({
    fre_full_name: "",
    fre_phone: "",
    fre_rut: "",
    fre_age_range: "",
  });

  const [errorPyme, setErrorsPyme] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");

  const { email, id } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  //LocalStorage
  const savedUser = localStorage.getItem("user");
  const savedUserGoogle = localStorage.getItem("user-google");
  const isPymeOrFree = localStorage.getItem("isPyme");
  let finalRut;
  let isPymeObject;
  if (isPymeOrFree) {
    isPymeObject = JSON.parse(isPymeOrFree);
  }
  let isPyme;

  if (isPymeObject === "true") {
    isPyme = true;
  }

  if (isPymeObject === "false") {
    isPyme = false;
  }

  let userObject;
  let userGoogleObject;

  if (savedUser) {
    userObject = JSON.parse(savedUser);
  }

  if (savedUserGoogle) {
    userGoogleObject = JSON.parse(savedUserGoogle);
  }

  let fullName = false;

  if (savedUserGoogle) {
    fullName = true;
  }

  useEffect(() => {
    if (fullName) {
      setFormData((prevState) => ({
        ...prevState,
        fre_full_name: userGoogleObject.displayName,
      }));
    }
  }, [fullName, userGoogleObject]);

  useEffect(() => {
    let savedPyme = null;
    let savedFree = null;

    if (isPyme) {
      savedPyme = localStorage.getItem("pyme");
    } else {
      savedFree = localStorage.getItem("freelancer");
    }

    if (savedPyme) {
      try {
        const parsedPyme = JSON.parse(savedPyme);
        setFormDataPyme(parsedPyme);
      } catch (error) {
        console.error("Error parsing savedPyme:", error);
      }
    }

    if (savedFree) {
      try {
        const parsedFree = JSON.parse(savedFree);
        setFormData(parsedFree);
      } catch (error) {
        console.error("Error parsing savedFree:", error);
      }
    }
  }, [isPyme]);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[0-9]{8,}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "rut") {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "fre_full_name" && fullName) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVolver = () => {
    navigate(`/selectType`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    let newErrors = {
      fre_full_name: "",
      fre_phone: "",
      fre_rut: "",
      fre_age_range: "",
    };

    setErrorMessage(""); // Limpiar mensajes anteriores
    setSuccessMessage(""); // Limpiar mensajes anteriores

    if (isPyme) {
      if (
        !fullName &&
        formData.fre_full_name === "" &&
        formDataPyme.pym_full_name === ""
      ) {
        newErrors.fre_full_name = "Debe ingresar un nombre";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (
        !validatePhoneNumber(formData.fre_phone) &&
        !validatePhoneNumber(formDataPyme.pym_phone)
      ) {
        newErrors.fre_phone = "Formato de numero inválido";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (formData.fre_rut) {
        finalRut = formData.fre_rut + formData.rut;
        
      } else {
        finalRut = formDataPyme.pym_rut + formDataPyme.rut;
      }

      if (formData.fre_rut === "" && formDataPyme.pym_rut === "") {
        newErrors.fre_rut = "Debe ingresar un RUT";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      } else if (!validateRut(finalRut || "")) {
        newErrors.fre_rut = "RUT inválido";
          setErrorMessage("Debe completar todos los datos");
          setSnackbarType("error");
          setOpenSnackbar(true);
          valid = false;
      } 

      if (formData.fre_age_range === "" && formDataPyme.pym_company_position === "") {
        newErrors.fre_age_range = "Debe seleccionar un rango etario";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (formDataPyme.pym_company_name === "") {
        setErrorsPyme("Debe ingresar el nombre de la Compañia");
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (!valid) {
        setErrors(newErrors);
        return;
      }
    } else {
      if (!fullName && formData.fre_full_name === "") {
        newErrors.fre_full_name = "Debe ingresar un nombre";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (!validatePhoneNumber(formData.fre_phone)) {
        newErrors.fre_phone = "Formato de numero inválido";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (formData.fre_rut === "") {
        newErrors.fre_rut = "Debe ingresar un RUT";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      } else {
        finalRut = formData.fre_rut + formData.rut;
        if (!validateRut(finalRut || "")) {
          newErrors.fre_rut = "RUT inválido";
          setErrorMessage("Debe completar todos los datos");
          setSnackbarType("error");
          setOpenSnackbar(true);
          valid = false;
        }
      }

      if (formData.fre_age_range === "") {
        newErrors.fre_age_range = "Debe seleccionar un rango etario";
        setErrorMessage("Debe completar todos los datos");
        setSnackbarType("error");
        setOpenSnackbar(true);
        valid = false;
      }

      if (!valid) {
        setErrors(newErrors);
        return;
      }
    }

    if (isPyme) {
      const plans = localStorage.getItem("selected-plan-id");
      const plansID = JSON.parse(plans);

      const pyme = {
        pym_company_name: companyName || formDataPyme.pym_company_name,
        pym_full_name: formData.fre_full_name || formDataPyme.pym_full_name,
        pym_phone: formData.fre_phone || formDataPyme.pym_phone,
        pym_rut: formData.fre_rut || formDataPyme.pym_rut,
        pym_company_position: formData.fre_age_range || formDataPyme.pym_company_position,
        pla_id: plansID,
        rut: formData.rut || formDataPyme.rut,
      };
      //localsotrage
      localStorage.setItem("pyme", JSON.stringify(pyme));
      navigate(`/create-account/specialization/?isPyme=${isPyme}`);
    } else {
      dispatch(
        setFreelancerPyme({
          ...formData,
          fre_rut: finalRut,
        })
      );
      //localsotrage
      localStorage.setItem(
        "freelancer",
        JSON.stringify({
          ...formData,
          fre_rut: formData.fre_rut,
          rut: formData.rut,
        })
      );
      navigate(`/create-account/specialization/?isPyme=${isPyme}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //Validacion de RUT

  function validateRut(rutConFormato) {
    const rutLimpio = rutConFormato.replace(/[.-]/g, "");

    const rut = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1);

    const rutEsValido = /^[0-9]{8,}$/.test(rut);

    const dvEsValido = /^[0-9Kk]$/.test(dv);

    if (rutEsValido && dvEsValido) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
          width: "80%",
          flexDirection: "column",
        }}
      >
        <Grid2 container rowSpacing={5} columnSpacing={2} sx={{ pt: "5%" }}>
          {/* nombre empresa */}
          {isPyme && (
            <Grid2 size={{ xs: 12 }}>
              <TextFieldCustom
                label={"Nombre de la empresa"}
                nameTextField={"pym_company_name"}
                required={true}
                handleChange={(e) => {
                  const value = e.target.value;
                  setCompanyName(value);
                  setFormDataPyme((prev) => ({
                    ...prev,
                    pym_company_name: value,
                  }));
                }}
                value={formDataPyme.pym_company_name || companyName}
                errors={!!errorPyme}
                helperText={errorPyme}
              />
            </Grid2>
          )}

          {/* correo electronico */}
          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={"Correo Electronico"}
              nameTextField={"email"}
              disabled={true}
              required={true}
              value={userObject?.email || ""}
            />
          </Grid2>
          {/* nombre Completo */}
          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={"Nombre completo"}
              disabled={fullName ? true : false}
              required={true}
              nameTextField={"fre_full_name"}
              value={
                fullName
                  ? userGoogleObject.displayName
                  : formData.fre_full_name || formDataPyme.pym_full_name
              }
              handleChange={handleChange}
              errors={!!errors.fre_full_name}
              helperText={errors.fre_full_name}
            />
          </Grid2>
          {/* telefono */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextFieldCustom
              label={"Teléfono/ Whatsapp"}
              required={true}
              nameTextField={"fre_phone"}
              type="number"
              handleChange={handleChange}
              value={formData.fre_phone || formDataPyme.pym_phone}
              errors={!!errors.fre_phone}
              helperText={errors.fre_phone}
              placeholder={"+5696885398"}
            />
          </Grid2>
          {/* rut */}
          <Grid2 size={{ xs: 8, md: 4 }}>
            <TextFieldCustom
              label={isPyme ? "Rut empresa" : "Rut"}
              required={true}
              nameTextField={"fre_rut"}
              handleChange={handleChange}
              value={formData.fre_rut || formDataPyme.pym_rut}
              errors={!!errors.fre_rut}
              helperText={errors.fre_rut}
              placeholder={"12.865.877"}
            />
          </Grid2>
          <Grid2 sx={{ marginTop: "30px", color: "#DBDCE5" }}>
            <Typography variant="h6">-</Typography>
          </Grid2>

          <Grid2 size={{ xs: 2.5, md: 1 }} sx={{ marginTop: "22px" }}>
            <TextFieldCustom
              required={true}
              nameTextField="rut"
              handleChange={handleChange}
              value={formData.rut || formDataPyme.rut}
              placeholder={"K"}
              showAsterisk={false}
              inputProps={{ maxLength: 1 }}
              errors={!!errors.fre_rut}
              // helperText={errors.fre_rut}
            />
          </Grid2>

          {/* rango etario */}
          {!isPyme && (
            <Grid2 size={{ xs: 12 }}>
              <Typography
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Selecciona tu rango etario {<b style={{ color: "red" }}>*</b>}
              </Typography>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="fre_age_range"
                  value={formData.fre_age_range}
                  onChange={handleChange}
                  errors={!!errors.fre_age_range}
                  helperText={errors.fre_age_range}
                >
                  {ageRanges.map((range, index) => (
                    <FormControlLabel
                      key={index}
                      value={range.name}
                      control={<RadioCustom />}
                      label={range.label}
                      sx={{
                        color: "#747684",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "400",
                        flexGrow: 1,
                      }}
                      onChange={handleChange}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid2>
          )}
          {/* cargo empresa */}
          {isPyme && (
            <Grid2 size={{ xs: 12 }}>
              <Typography
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Cargo en la empresa {<b style={{ color: "red" }}>*</b>}
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="fre_age_range"
                  value={
                    formData.fre_age_range || formDataPyme.pym_company_position
                  }
                  onChange={handleChange}
                  errors={!!errors.fre_age_range}
                  helperText={errors.fre_age_range}
                >
                  <Grid2 container spacing={1}>
                    {companyCharges.map((range, index) => (
                      <Grid2 size={{ xs: 6 }} key={{ index }}>
                        <FormControlLabel
                          value={range.name}
                          control={<RadioCustom />}
                          label={range.label}
                          sx={{
                            color: "#747684",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                          onChange={handleChange}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                </RadioGroup>
              </FormControl>
            </Grid2>
          )}
        </Grid2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pb: "5%",
          }}
        >
          {isPyme ? (
            <Box></Box>
          ) : (
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                color: "#e3602d",
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
              }}
              startIcon={<ArrowBackRounded />}
              onClick={handleVolver}
            >
              Volver
            </Button>
          )}
          <Button
            variant="filled"
            sx={{
              textTransform: "none",
              bgcolor: "#e3602d",
              color: "#ffffff",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
            }}
            endIcon={<ArrowForwardRounded />}
            onClick={handleSubmit}
          >
            Continuar
          </Button>
        </Box>
      </Box>

      {/* Alerta flotante */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
