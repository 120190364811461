import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../../services/userService";
import { getPymeByUsrId } from "../../../services/pymeService";
import { getOneFreelancerbyID } from "../../../services/freelancerService";

const pages = ["Home", "Mis proyectos"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function Navbar({ isPyme }) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isLoginbject, setIsLoginbject] = useState(null);
  const [pyme, setPyme] = useState(null);
  const [free, setFree] = useState(null);

  const loadUserLogin = () => {
    const savedLogin = localStorage.getItem("user-login");
    if (savedLogin) {
      const parsedLogin = JSON.parse(savedLogin);
      setIsLoginbject(parsedLogin);
    } else {
      console.error("No se encontró 'user-login' en localStorage");
      setTimeout(loadUserLogin, 500);
    }
  };

  useEffect(() => {
    loadUserLogin();
  }, []);

  useEffect(() => {
    if (isLoginbject && isPyme !== null) {
      const getInfo = async () => {
        try {
          if (isPyme) {
            const infoPyme = await getPymeByUsrId(isLoginbject.usr_id);
            setPyme(infoPyme.data.data);
          } else {
            const infoFree = await getOneFreelancerbyID(isLoginbject.usr_id);
            setFree(infoFree.data.data);
          }
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };
      getInfo();
    }
  }, [isLoginbject, isPyme]);

  const imageProfile = isPyme
    ? pyme?.pym_photo
    : free?.fre_photo || "/static/img/profile-sin-img.png";

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    if (isPyme) {
      navigate("/account/pyme");
    } else {
      navigate("/account/freelancer");
    }
  };

  const handleLogout = async () => {
    try {
      await logout(isLoginbject.usr_id);
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error("Error al crear experiencia:", error);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    navigate("/home");
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    // <AppBar position="static" sx={{ background: "#fff" }}>
    <AppBar position="fixed" sx={{ background: "#fff", marginBottom: '764px'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="/static/img/logo-navbar-account.png"
            alt="Logo"
            style={{ display: "flex", marginRight: "8px" }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  color: "#000",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!isMobile ? (
              <>
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ChatOutlinedIcon sx={{ color: "#000" }} />
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "25.6px",
                      color: "#191A24",
                      ml: "7px",
                    }}
                  >
                    Mensajes
                  </Typography>
                </IconButton>

                <IconButton
                  sx={{
                    ml: "15px",
                    mr: "15px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <NotificationsRoundedIcon sx={{ color: "#000" }} />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ChatOutlinedIcon sx={{ color: "#000" }} />
                </IconButton>

                <IconButton
                  sx={{
                    ml: "15px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <NotificationsRoundedIcon sx={{ color: "#000" }} />
                </IconButton>

                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  sx={{
                    ml: "15px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    color: "#000",
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}

            <Tooltip title="">
              {!isMobile ? (
                <>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Avatar alt="Remy Sharp" src={imageProfile} />
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "25.6px",
                        color: "#191A24",
                        ml: "7px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      Mi perfil
                    </Typography>
                  </IconButton>

                  <IconButton
                    onClick={handleLogout}
                    sx={{
                      p: 0,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      marginLeft: 2,
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: "block", md: "none" } }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography sx={{ textAlign: "center", color: "#000" }}>
                          {page}
                        </Typography>
                      </MenuItem>
                    ))}

                    <MenuItem onClick={handleOpenUserMenu}>
                      <IconButton
                        sx={{
                          p: 0,
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Avatar alt="Remy Sharp" src={imageProfile} />
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "25.6px",
                            color: "#191A24",
                            ml: "7px",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          Mi perfil
                        </Typography>
                      </IconButton>
                    </MenuItem>

                    <MenuItem onClick={handleLogout}>
                      <IconButton
                        sx={{
                          p: 0,
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <LogoutIcon />
                      </IconButton>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
