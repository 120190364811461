import { createSlice } from "@reduxjs/toolkit";

export const freelancerPymeSlice = createSlice({
  name: "freelancerPyme",
  initialState: {
    fre_full_name: "",
    fre_phone: "",
    fre_rut: 0,
    fre_age_range: "",
    fre_professional_experience: "",
    fre_why_join: "",
    fre_employment_situation: "",
    fre_social_media: "",
    usr_id: 0,
    specialization: [],
  },
  reducers: {
    setFreelancerPyme: (state, action) => {
      const {
        fre_full_name,
        fre_phone,
        fre_rut,
        fre_age_range,
        fre_professional_experience,
        fre_why_join,
        fre_employment_situation,
        fre_social_media,
        usr_id,
        specialization,
      } = action.payload;
      state.fre_full_name = fre_full_name;
      state.fre_phone = fre_phone;
      state.fre_rut = fre_rut;
      state.fre_age_range = fre_age_range;
      state.fre_professional_experience = fre_professional_experience;
      state.fre_why_join = fre_why_join;
      state.fre_employment_situation = fre_employment_situation;
      state.fre_social_media = fre_social_media;
      state.usr_id = usr_id;
      state.specialization = specialization;
    },
    clearFreelancerPyme: (state) => {
      state.fre_full_name = "";
      state.fre_phone = "";
      state.fre_rut = 0;
      state.fre_age_range = "";
      state.fre_professional_experience = "";
      state.fre_why_join = "";
      state.fre_employment_situation = "";
      state.fre_social_media = "";
      state.usr_id = 0;
      state.specialization = [];
    },
  },
});

export const { setFreelancerPyme, clearFreelancerPyme } =
  freelancerPymeSlice.actions;

export default freelancerPymeSlice.reducer;
