import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SelectCustom({
  fullWidth = true,
  label,
  required = false,
  disabled = false,
  nameSelect,
  value = "",
  handleChange,
  options = [],
  errors,
  helperText,
  helperTopText,
  showAsterisk = true,
}) {
  return (
    <FormControl fullWidth={fullWidth} error={errors}>
      <FormLabel
        sx={{
          color: "#3E1051",
          fontStyle: "Inter",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "21.78px",
        }}
      >
        {label} {required && showAsterisk && <b style={{ color: "red" }}>*</b>}
        {helperTopText && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "16.94px",
              textAlign: "left",
              color: "#747684",
              marginBottom: 2,
            }}
          >
            {helperTopText}
          </Typography>
        )}
      </FormLabel>

      <Select
        sx={{
          borderRadius: "10px !important",
          backgroundColor: disabled ? "#DBDCE5" : "inherit",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: errors ? "red" : "#ccc",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: errors ? "red" : "#999",
          },
        }}
        disabled={disabled}
        value={value}
        name={nameSelect}
        onChange={handleChange}
        displayEmpty
        variant="outlined"
        IconComponent={ExpandMoreIcon}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}  