import {
  Box,
  Typography,
  Button,
  Grid2,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Snackbar,
  Alert,
  useMediaQuery,
  Modal,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldCustom from "../../TextField/TextFieldCustom";
import RadioCustom from "../../RadioCustom/RadioCustom";
import SelectCustom from "../../Select/SelectCustom";
import {
  getOneFreelancerbyID,
  updateFreelancer,
} from "../../../../services/freelancerService";
import {
  createSocialMedia,
  deleteSocialMedia,
  getSocialMediaByID,
} from "../../../../services/userService";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { LoadingButton } from "@mui/lab";

export default function Information({ free }) {
  const [newFree, setnewFree] = useState(free);
  const [data, setData] = useState(free);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [openDelete, setOpenDelete] = useState(false);
  const [idSom, setIdSom] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);

  let usr;

  if (free) {
    const { usr_id } = free;
    usr = usr_id;
  }

  const handleOpenDelete = (id) => {
    setIdSom(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "42%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    try {
      handleUserInformation();
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleUserInformation = async () => {
    setLoading(true);
    const freeServiceNoPrice = await getOneFreelancerbyID(usr.usr_id);
    setnewFree(freeServiceNoPrice.data.data);
    setData(newFree);
    const socialMediaData = newFree.usr_id.socialMedia || [];

    const filledSocialMedia = [...socialMediaData];

    while (filledSocialMedia.length < 5) {
      filledSocialMedia.push({ som_name: "", som_url: "" });
    }

    setSocialMedia(filledSocialMedia);
    setLoading(false);
  };

  const ageRanges = [
    { name: "25-32", label: "25-32" },
    { name: "33-40", label: "33-40" },
    { name: "41-48", label: "41-48" },
    { name: "49-56", label: "49-56" },
    { name: "57-64", label: "57-64" },
  ];

  const situation = [
    { id: 1, label: "Dependiente Jornada completa (puedo acomodar horarios)" },
    {
      id: 2,
      label: "Independiente sin horario fijo (puedo acomodar horarios)",
    },
    { id: 3, label: "Independiente horario fijo (puedo acomodar horarios)" },
    {
      id: 4,
      label: "Socio (a) - Dueño(a)  de negocio (puedo acomodar  mis horarios",
    },
    { id: 5, label: "Sin empleo actual" },
  ];

  const [socialMedia, setSocialMedia] = useState();

  const [editing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[0-9]{8,}$/; // Signo + opcional, seguido de mínimo 8 números
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index][field] = value;
    setSocialMedia(updatedSocialMedia);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      let newErrors = {};

      if (!data.fre_phone) newErrors.fre_phone = "Este campo es requerido";

      if (!validatePhoneNumber(data.fre_phone)) {
        newErrors.fre_phone = "Formato de numero inválido";
      }

      if (!data.fre_professional_experience)
        newErrors.fre_professional_experience = "Este campo es requerido";
      if (!data.fre_why_join)
        newErrors.fre_why_join = "Este campo es requerido";

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      const freeID = Number(free.fre_id);
      const newData = {
        fre_phone: data.fre_phone,
        fre_age_range: data.fre_age_range,
        fre_professional_experience: data.fre_professional_experience,
        fre_why_join: data.fre_why_join,
        fre_employment_situation: data.fre_employment_situation,
      };

      const userID = Number(free.usr_id.usr_id);

      await Promise.all(
        socialMedia?.map(async (media) => {
          if (media.som_name && media.som_url) {
            const socialMediaData = {
              som_name: media.som_name,
              som_url: media.som_url,
              usr_id: userID,
            };
            if (media.som_id) {
              const originalMedia = await getSocialMediaByID(media.som_id);

              //   debugger;
              if (
                originalMedia.data.data.som_name !== media.som_name ||
                originalMedia.data.data.som_url !== media.som_url
              ) {
                await deleteSocialMedia(media.som_id);
                await createSocialMedia(socialMediaData);
              }
            } else {
              await createSocialMedia(socialMediaData);
            }
          }
        })
      );

      // Actualizar freelancer después de procesar las redes sociales
      await updateFreelancer(freeID, newData);
      handleUserInformation();
      // window.location.reload();
      setSuccessMessage("Freelancer actualizado correctamente");
      setSnackbarType("success");
      setOpenSnackbar(true);
      setEditing(false);
      setLoading(false);
    } catch (error) {
      console.error("Error al actualizar el freelancer:", error);
      setErrorMessage(
        "Error al actualizar el freelancer. Por favor, intenta nuevamente."
      );
      setSnackbarType("error");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const formatRUT = (rut) => {
    const cleanedRut = rut.replace(/\D/g, "");

    if (cleanedRut.length === 9) {
      const body = cleanedRut.slice(0, 8);
      const checkDigit = cleanedRut.charAt(8);
      return `${body.slice(0, 2)}.${body.slice(2, 5)}.${body.slice(
        5,
        8
      )}-${checkDigit}`;
    }
    return rut;
  };

  const handleDeleteSocialMedia = async () => {
    try {
      // Eliminar de la base de datos
      await deleteSocialMedia(idSom);

      // Filtrar la red social eliminada de la lista local
      const updatedSocialMedia = socialMedia.filter(
        (som) => som.som_id !== idSom
      );
      setSocialMedia(updatedSocialMedia);

      // Cerrar el modal
      handleCloseDelete();
    } catch (error) {
      console.log("error al eliminar red social", error);
    }
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress size={28} sx={{ color: "#eba34f" }} />
        </Box>
      ) : !editing ? (
        <>
          <Button
            className="button-add"
            sx={{ mb: "20px" }}
            onClick={() => handleEditClick()}
          >
            Editar información
          </Button>
          <Box>
            <Typography className="info-title">Correo electrónico</Typography>
            {newFree.usr_id && newFree.usr_id.usr_email && (
              <Typography className="info-subtitle">
                {newFree.usr_id.usr_email}
              </Typography>
            )}

            <Typography className="info-title">Telefono/Whatsapp</Typography>
            {newFree.fre_phone && (
              <Typography className="info-subtitle">
                {newFree.fre_phone}
              </Typography>
            )}

            <Typography className="info-title">Rut</Typography>
            {newFree.fre_rut && (
              <Typography className="info-subtitle">
                {formatRUT(newFree.fre_rut)}
              </Typography>
            )}

            <Typography className="info-title">
              Selecciona tu rango etario
            </Typography>
            {newFree.fre_age_range && (
              <Typography className="info-subtitle">
                {newFree.fre_age_range}
              </Typography>
            )}

            <Typography className="info-title">
              Experiencia profesional
            </Typography>
            {newFree.fre_professional_experience && (
              <Typography className="info-subtitle">
                {newFree.fre_professional_experience}
              </Typography>
            )}

            <Typography className="info-title">
              ¿Por qué te gustaría unirte a Dreup y ayudar a las PYMES a crecer?
            </Typography>
            {newFree.fre_why_join && (
              <Typography className="info-subtitle">
                {newFree.fre_why_join}
              </Typography>
            )}

            <Typography className="info-title">
              Cuéntanos sobre tu actual situación laboral
            </Typography>
            {newFree.fre_employment_situation && (
              <Typography className="info-subtitle">
                {newFree.fre_employment_situation}
              </Typography>
            )}

            <Typography className="info-title">
              Ingresa tus RRSS para ser contactado
            </Typography>
            {newFree.usr_id.socialMedia.map((som) => (
              <Typography className="info-rrss">{som.som_url}</Typography>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Grid2 container rowSpacing={5} columnSpacing={2}>
            <TextFieldCustom
              label={"Teléfono/Whatsapp"}
              nameTextField={"fre_phone"}
              required={true}
              rows={5}
              labelSx={{ mb: "16px" }}
              value={data.fre_phone}
              handleChange={handleChange}
              errors={!!errors.fre_phone}
              helperText={errors.fre_phone}
            />

            <Grid2 container rowSpacing={1} columnSpacing={2}>
              <Typography
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
                labelSx={{ mb: "16px" }}
              >
                Selecciona tu rango etario {<b style={{ color: "red" }}>*</b>}
              </Typography>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="fre_age_range"
                  value={data.fre_age_range}
                  onChange={handleChange}
                >
                  {ageRanges.map((range, index) => (
                    <FormControlLabel
                      key={index}
                      value={range.name}
                      control={<RadioCustom />}
                      label={range.label}
                      sx={{
                        color: "#747684",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "400",
                        flexGrow: 1,
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid2>

            <TextFieldCustom
              label={"Experiencia profesional"}
              helperTopText={`\nCuéntanos una breve reseña laboral de tu vida.\n
                                    Trata de destacar los hitos más importantes que te han llevado a ser un experto en el área donde puedes ayudar y contribuir al crecimiento de las Pymes.`}
              nameTextField={"fre_professional_experience"}
              required={true}
              multiline={true}
              rows={5}
              value={data.fre_professional_experience}
              handleChange={handleChange}
              errors={!!errors.fre_professional_experience}
              helperText={errors.fre_professional_experience}
            />

            <TextFieldCustom
              label={
                "¿Por qué te gustaría unirte a Dreup y ayudar a las PYMES a crecer?"
              }
              nameTextField={"fre_why_join"}
              required={true}
              multiline={true}
              rows={5}
              labelSx={{ mb: "16px" }}
              value={data.fre_why_join}
              handleChange={handleChange}
              errors={!!errors.fre_why_join}
              helperText={errors.fre_why_join}
            />

            <Grid2 container rowSpacing={1} columnSpacing={2}>
              <Typography
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Cuéntanos sobre tu actual situación laboral{" "}
                {<b style={{ color: "red" }}>*</b>}
              </Typography>
              <FormControl fullWidth>
                <RadioGroup
                  column
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="fre_employment_situation"
                  value={data.fre_employment_situation}
                  onChange={handleChange}
                >
                  {situation.map((situation, index) => (
                    <FormControlLabel
                      key={index}
                      value={situation.label}
                      control={<RadioCustom />}
                      label={situation.label}
                      sx={{
                        color: "#747684",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "400",
                        flexGrow: 1,
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid2>

            <FormLabel
              sx={{
                color: "#3E1051",
                fontStyle: "Inter",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "21.78px",
              }}
            >
              Enlace a tu Portafolio ({" "}
              {
                <b style={{ color: "#ED8131" }}>
                  {" "}
                  Linkedin, Facebook, Instagram, GitHub, Gitlab, Web, Canvas,
                  Youtube, Pinteres.
                </b>
              }{" "}
              ) {<b style={{ color: "red" }}>*</b>}
            </FormLabel>
          </Grid2>
          <Grid2>
            <FormLabel
              sx={{
                color: "#747684",
                fontStyle: "Inter",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "21.78px",
              }}
            >
              Escribe tus 5 redes principales
            </FormLabel>

            {socialMedia.map((som, index) => (
              <Grid2
                container
                direction="row"
                rowSpacing={1}
                columnSpacing={3}
                key={index}
              >
                <Grid2 sx={{ width: { xs: "80%", md: "30%" }, mt: "5px" }}>
                  <SelectCustom
                    showAsterisk={false}
                    required
                    value={som.som_name || ""}
                    nameSelect={`som_name_${index}`}
                    options={[
                      { value: "Linkedin", label: "Linkedin" },
                      { value: "Facebook", label: "Facebook" },
                      { value: "Instagram", label: "Instagram" },
                      { value: "GitHub", label: "GitHub" },
                      { value: "Gitlab", label: "Gitlab" },
                      { value: "Web", label: "Web" },
                      { value: "Canvas", label: "Canvas" },
                      { value: "Youtube", label: "Youtube" },
                      { value: "Pinterest", label: "Pinterest" },
                    ]}
                    handleChange={(e) =>
                      handleSocialMediaChange(index, "som_name", e.target.value)
                    }
                  />
                </Grid2>

                <Grid2 sx={{ width: { xs: "80%", md: "53%" }, mt: "5px" }}>
                  <TextFieldCustom
                    required
                    showAsterisk={false}
                    nameTextField={`som_url_${index}`}
                    value={som.som_url || ""}
                    handleChange={(e) =>
                      handleSocialMediaChange(index, "som_url", e.target.value)
                    }
                  />
                </Grid2>
                {som.som_name && som.som_url && (
                  <Button
                    onClick={() => handleOpenDelete(som.som_id)}
                    style={{
                      background: "none",
                      border: "none",
                      padding: "3px",
                      minWidth: "unset",
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteOutlineOutlinedIcon className="delete-icon" />
                  </Button>
                )}
              </Grid2>
            ))}
          </Grid2>

          <Box className="modal-box-buttons">
            <Button className="modal-button button1" onClick={handleEditClick}>
              Cancelar
            </Button>
            <LoadingButton
              loading={loading}
              className="modal-button button2"
              onClick={handleSaveClick}
              loadingIndicator={
                <CircularProgress size={24} sx={{ color: "white" }} />
              }
            >
              Guardar
            </LoadingButton>
          </Box>
        </>
      )}

      {/* Modal delete social media */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(62, 16, 81, 0.8)",
          },
        }}
      >
        <Box sx={styleDelete}>
          <Box>
            <Typography
              id="modal-add"
              className="modal-add-title"
              sx={{ fontSize: "16px !important" }}
            >
              Eliminar red social
            </Typography>
            <FormLabel
              className="modal-add-subtitle"
              sx={{ color: "#747684 !important" }}
            >
              ¿Seguro que deseas eliminar?
            </FormLabel>
            <Box className="modal-box-buttons">
              <Button
                className="modal-button button1"
                onClick={handleCloseDelete}
              >
                Cancelar
              </Button>
              <Button
                className="modal-button button2"
                onClick={handleDeleteSocialMedia}
              >
                Si, eliminar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
