import {
  Box,
  Typography,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  FormLabel,
  TextField,
  useMediaQuery,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../../styles/body.css";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  deleteSpecializationFree,
  getSpecializationList,
  updateSpecializationFree,
} from "../../../../services/specializationService";
import GradeIcon from "@mui/icons-material/Grade";
import {
  createFreelancerService,
  deleteFreelancerService,
  getOneFreelancerbyID,
  getServiceByFreeID,
  updateFreelancerServices,
} from "../../../../services/freelancerService";
import { LoadingButton } from "@mui/lab";

export default function Service({ pyme, free }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");

  const [freelancer, setFreelancer] = useState();
  const [freelancerService, setFreelancerService] = useState();

  const [services, setServices] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  let usr;
  let usrSpecialization;

  if (free) {
    const { usr_id } = free;
    usr = usr_id;
    usrSpecialization = usr.userSpecialization || [];
  }

  const isMobile = useMediaQuery("(max-width:600px)");

  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  const [selectedIds, setSelectedIds] = useState(
    services.map((service) => service.id)
  );

  const handleAdd = async () => {
    setOpenAdd(true);
    await handleUserServices();
  };
  const handleCloseAdd = (e) => setOpenAdd(false);

  const [idSpecialization, setIdSpecialization] = useState();
  const [labelSpecialization, setLabelSpecialization] = useState();

  const handleDelete = async (serviceId, serviceLabel) => {
    setLoading(true);
    // debugger;
    await handleUserServices();
    const specialization = usrSpecialization.find(
      (specialization) => specialization.spe_id.spe_id === serviceId
    );

    if (specialization) {
      setIdSpecialization(specialization.fsp_id);
      setLabelSpecialization(serviceLabel);
      setOpenDelete(true);
      setLoading(false);
    } else {
      setLoading(false);
      console.log("No se encontró una especialización con ese spe_id");
    }
  };
  const handleCloseDelete = () => setOpenDelete(false);

  useEffect(() => {
    const fetchServiceList = async () => {
      // debugger;
      try {
        setLoading(true);
        await handleUserServices();
        setFreelancer(free);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching industry list:", error);
      }
    };

    fetchServiceList();
  }, []);

  const handleUserServices = async () => {
    // debugger;
    const list = await getSpecializationList();
    setServiceList(list.data.data);
    const freeService = await getServiceByFreeID(free.fre_id);
    const freeServiceNoPrice = await getOneFreelancerbyID(usr.usr_id);
    setFreelancerService(freeService.data.data);

    const freeServiceMap = (freeService.data.data || []).reduce((map, item) => {
      map[item.specialization.spe_id] = {
        id: item.specialization.spe_id,
        label: item.specialization.spe_name,
        price: item.fse_price,
        favorite: item.fse_favorite,
      };
      return map;
    }, {});

    // setUsrSpecialization(freeServiceNoPrice.data.data.usr_id.userSpecialization)
    usrSpecialization = freeServiceNoPrice.data.data.usr_id.userSpecialization;

    const combinedServices = (
      freeServiceNoPrice.data.data.usr_id.userSpecialization || []
    ).map((item) => {
      const freeServiceItem = freeServiceMap[item.spe_id.spe_id];

      return freeServiceItem
        ? {
            id: item.spe_id.spe_id,
            label: item.spe_id.spe_name,
            price: freeServiceItem.price,
            favorite: freeServiceItem.favorite,
          }
        : {
            id: item.spe_id.spe_id,
            label: item.spe_id.spe_name,
            price: null,
          };
    });

    const missingFromUserSpecialization = Object.values(freeServiceMap).filter(
      (freeServiceItem) =>
        !combinedServices.some((service) => service.id === freeServiceItem.id)
    );

    setServices([...combinedServices, ...missingFromUserSpecialization]);
  };

  const styleAdd = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "42%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const handleSelect = (specialtyId) => {
    const currentIndex = selectedIds.indexOf(specialtyId);
    const preSelectedCount = services.filter(
      (service) => service.price !== null
    ).length;
    const newTotalSelected = preSelectedCount + selectedIds.length;

    if (currentIndex === -1 && newTotalSelected >= 3) {
      setErrorMessage("Solo puedes seleccionar hasta 3 servicios.");
      setSnackbarType("error");
      setOpenSnackbar(true);
      return;
    }

    if (currentIndex === -1) {
      setSelectedIds([...selectedIds, specialtyId]);
    } else {
      const newSelectedIds = selectedIds.filter((id) => id !== specialtyId);
      setSelectedIds(newSelectedIds);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const newFreelancer = {
        specialization: selectedIds,
      };
      await updateSpecializationFree(freelancer.fre_id, newFreelancer);
      setOpenAdd(false);
      await handleUserServices();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Error al crear un servicio.");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (freelancer) {
      const updatedServices = freelancer.usr_id.userSpecialization.map(
        (item) => ({
          id: item.spe_id.spe_id,
          label: item.spe_id.spe_name,
          price: item.price || null,
        })
      );

      setServiceList(updatedServices);
    }
  }, [usrSpecialization]);

  const handleDeleteSpecialization = async () => {
    try {
      setLoading(true);
      const serviceToDelete = freelancerService.find(
        (service) => service.specialization.spe_name === labelSpecialization
      );

      await deleteSpecializationFree(idSpecialization);

      if (serviceToDelete && serviceToDelete.fse_id) {
        await deleteFreelancerService(serviceToDelete.fse_id);
      }

      const updatedSpecializations =
        freelancer.usr_id.userSpecialization.filter(
          (specialization) => specialization.fsp_id !== idSpecialization
        );

      const updatedFreelancer = {
        ...freelancer,
        usr_id: {
          ...freelancer.usr_id,
          userSpecialization: updatedSpecializations,
        },
      };

      setFreelancer(updatedFreelancer);
      localStorage.setItem("freelancer", JSON.stringify(updatedFreelancer));
      setSuccessMessage("Servicio eliminado exitosamente");
      setSnackbarType("success");
      setOpenSnackbar(true);
      setOpenDelete(false);
      await handleUserServices();
      setLoading(false);
      // window.location.reload();
    } catch (error) {
      setErrorMessage("Error al eliminar el servicio.");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const [editingServiceId, setEditingServiceId] = useState(null);

  const [isAnyFavorite, setIsAnyFavorite] = useState(false);

  const handleStarClick = async (serviceId) => {
    try {
      const serviceToFavorite = services.find(
        (service) => service.id === serviceId
      );

      if (!serviceToFavorite || serviceToFavorite.price === null) {
        setErrorMessage(
          "Solo puedes marcar como favorito un servicio con precio."
        );
        setSnackbarType("error");
        setOpenSnackbar(true);
        return;
      }

      const updatedServices = services.map((service) => ({
        ...service,
        favorite: service.id === serviceId ? !service.favorite : false,
      }));

      const isFavorite = updatedServices.some((service) => service.favorite);

      setServices(updatedServices);
      setIsAnyFavorite(isFavorite);

      for (const service of updatedServices) {
        const favoriteService = {
          spe_id: service.id,
          fre_id: free.fre_id,
          fse_favorite: service.id === serviceId ? service.favorite : false,
        };

        if (service.price !== null) {
          await updateFreelancerServices(favoriteService);
        }
      }
    } catch (error) {
      setErrorMessage("Error al actualizar el estado favorito.");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  };

  const [currentServicePrice, setCurrentServicePrice] = useState(null);
  const [currentServiceId, setCurrentServiceId] = useState(null);

  const handleEditClick = (serviceId) => {
    if (editingServiceId === serviceId) {
      setEditingServiceId(null);
      setCurrentServiceId(null);
      setCurrentServicePrice(null);
    } else {
      setEditingServiceId(serviceId);
      setCurrentServiceId(serviceId);
      const service = services.find((service) => service.id === serviceId);
      setCurrentServicePrice(service ? service.price : "");
    }
  };

  const handleSavePrice = async () => {
    setLoading(true);
    if (!currentServiceId) {
      console.error("No hay un servicio seleccionado para guardar.");
      return;
    }

    try {
      const freService = {
        fse_price: parseInt(currentServicePrice),
        fse_favorite: false,
        spe_id: currentServiceId,
        fre_id: freelancer.fre_id,
      };
      await createFreelancerService(freService);
      setSuccessMessage("Precio guardado exitosamente");
      setSnackbarType("success");
      setOpenSnackbar(true);

      const updatedServices = serviceList.map((service) =>
        service.id === currentServiceId
          ? { ...service, price: parseInt(currentServicePrice) }
          : service
      );
      setServiceList(updatedServices);
      setEditingServiceId(null);
      setCurrentServiceId(null);
      setCurrentServicePrice(null);
      await handleUserServices();
      setLoading(false);
    } catch (error) {
      setErrorMessage("Error al guardar el precio.");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  let disableBtn = false;
  if (services.length >= 3) {
    disableBtn = true;
  }

  const uniqueServices = services.filter(
    (service, index, self) =>
      index === self.findIndex((s) => s.id === service.id)
  );

  return (
    <>
      <Box className="box-service">
        <Box className="box-service2">
          <Typography className="title">Servicios</Typography>
          <Button
            className="button-add"
            onClick={handleAdd}
            disabled={disableBtn}
          >
            Agregar servicio
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress size={28} sx={{ color: "#eba34f" }} />
        </Box>
      ) : (
        uniqueServices.map((service) => (
          <Box key={service.id} className="services-list">
            <Box className="box-iconos">
              <Typography className="service">{service.label}</Typography>
              {editingServiceId !== service.id && (
                <Box className="box-iconos2">
                  <Button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "3px",
                      minWidth: "unset",
                      cursor:
                        (!service.favorite && isAnyFavorite) ||
                        service.price === null
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() => {
                      if (
                        (!isAnyFavorite || service.favorite) &&
                        service.price !== null
                      ) {
                        handleStarClick(service.id);
                      }
                    }}
                    disabled={
                      (!service.favorite && isAnyFavorite) ||
                      service.price === null
                    } // Deshabilita el botón si ya hay un favorito o no tiene precio
                  >
                    {service.favorite ? (
                      <GradeIcon sx={{ color: "#eba34f" }} />
                    ) : (
                      <GradeOutlinedIcon sx={{ color: "#c0c2d0" }} />
                    )}
                  </Button>

                  <Button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "3px",
                      minWidth: "unset",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditClick(service.id)}
                  >
                    <EditOutlinedIcon className="edit-icon" />
                  </Button>
                  <Button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "3px",
                      minWidth: "unset",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(service.id, service.label)}
                  >
                    <DeleteOutlineOutlinedIcon className="delete-icon" />
                  </Button>
                </Box>
              )}
            </Box>
            {editingServiceId === service.id ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  className="box-price"
                >
                  <Typography variant="body1">$</Typography>
                  <TextField
                    value={currentServicePrice || ""}
                    onChange={(e) => setCurrentServicePrice(e.target.value)}
                    variant="outlined"
                    className="description-account"
                    sx={{ flexGrow: 1, mx: 1, width: "100px" }}
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                  <Typography variant="body1">/hr</Typography>
                </Box>
                <Box className="modal-box-buttons" sx={{ mt: "10px" }}>
                  <LoadingButton
                    loading={loading}
                    className="modal-button button2"
                    onClick={handleSavePrice}
                    loadingIndicator={
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    }
                  >
                    Guardar
                  </LoadingButton>
                  <Button
                    className="modal-button button1"
                    onClick={() => handleEditClick(service.id)}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography className="price">
                {formatPrice(service.price)} /hr
              </Typography>
            )}
          </Box>
        ))
      )}
      <Box>
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(62, 16, 81, 0.8)",
            },
          }}
        >
          <Box sx={styleAdd}>
            <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
              <Typography id="modal-add" className="modal-add-title">
                Agregar servicio
              </Typography>
              <FormLabel className="modal-add-subtitle">
                Título Categotia
              </FormLabel>
              <Box>
                {serviceList.map((specialty) => (
                  <Box key={specialty.spe_id}>
                    <FormControlLabel
                      sx={{
                        alignItems: "flex-start",
                      }}
                      control={
                        <Checkbox
                          checked={
                            selectedIds.includes(specialty.spe_id) ||
                            services.some(
                              (service) => service.id === specialty.spe_id
                            )
                          }
                          onChange={() => handleSelect(specialty.spe_id)}
                          sx={{
                            color: "#DBDCE5",
                            "&.Mui-checked": {
                              color: "#ED8131",
                            },
                            mt: "-8px",
                          }}
                          disabled={
                            services.filter((service) => service.price !== null)
                              .length +
                              selectedIds.length >=
                              3 && !selectedIds.includes(specialty.spe_id)
                          }
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{ display: "block", color: "#747684" }}
                          >
                            {specialty.spe_name}
                          </Typography>
                          {specialty.spe_description && (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ display: "inline-block" }}
                            >
                              {specialty.spe_description}
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                  </Box>
                ))}
              </Box>
              <Box className="modal-box-buttons">
                <Button
                  className="modal-button button1"
                  onClick={handleCloseAdd}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={loading}
                  className="modal-button button2"
                  onClick={handleSave}
                  loadingIndicator={
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  }
                >
                  Guardar
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(62, 16, 81, 0.8)",
            },
          }}
        >
          <Box sx={styleDelete}>
            <Box>
              <Typography
                id="modal-add"
                className="modal-add-title"
                sx={{ fontSize: "16px !important" }}
              >
                Eliminar servicio
              </Typography>
              <FormLabel
                className="modal-add-subtitle"
                sx={{ color: "#747684 !important" }}
              >
                ¿Seguro que deseas eliminar este servicio?
              </FormLabel>
              <Box className="modal-box-buttons">
                <Button
                  className="modal-button button1"
                  onClick={handleCloseDelete}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={loading}
                  className="modal-button button2"
                  onClick={handleDeleteSpecialization}
                  loadingIndicator={
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  }
                >
                  Si, eliminar servicio
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarType === "success" ? successMessage : errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
