import React from "react";
import "../Styles/signup_login.css";
import Navbar from "../../components/shared/navbarSignup";
import SignupBox from "../../components/shared/signupBox";

const Signup = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Navbar />
      <div className="signup-login-container">
        <h1>¿Cómo deseas registrarte?</h1>
        <SignupBox />
        <h1 className="text-inferior">
          ¿Ya tienes una cuenta? <br/>
          <a
            href="/login"
            className="iniciar-sesion"
          >
            Iniciar sesión
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Signup;
