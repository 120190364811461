import {
  Box,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  useMediaQuery,
  CircularProgress,
  Modal,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../config/firebaseConfig";
import {
  createFreelancerPortfolio,
  getFreelancerPortfolioByfreId,
  deleteFreePortfolio,
} from "../../../../services/freelancerService";
import { ref as storageRef, deleteObject } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { validateFile } from "../../../../utils/fileValidation";

export default function Portfolio({ free }) {
  const MAX_FILES = 5;
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [portfolioData, setPortfolioData] = useState([]);
  const [compressedFileUrl, setCompressedFileUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        handlePortfolio();
      } catch (error) {
        console.error("Error al obtener los datos del portafolio:", error);
      }
    };

    fetchData();
  }, []);

  const handlePortfolio = async () => {
    setLoading(true);
    const portfolio = await getFreelancerPortfolioByfreId(free.fre_id);
    const imgList = [];
    const docList = [];
    const data = portfolio.data.data;
    setPortfolioData(portfolio.data.data);
    data.map((item) => {
      if (item.fsp_type.startsWith("image/")) {
        imgList.push(item);
      } else {
        docList.push(item);
      }
    });
    setImages(imgList);
    setDocuments(docList);
    setLoading(false);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const validation = validateFile(selectedFile);

    if (!validation.isValid) {
      setErrorMessage(validation.error);
      return;
    }

    const isImage = selectedFile.type.startsWith("image/");


    try {
      setFile(selectedFile);
      await handleUpload(selectedFile);

      if (isImage) {
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(selectedFile, options);
        console.log(`Archivo comprimido: ${compressedFile.size / 1024} KB`);

        const compressedFileWithNewName = new File(
          [compressedFile],
          `${selectedFile.name
            .split(".")
            .slice(0, -1)
            .join(".")}_fsp_thumbnail.${selectedFile.name.split(".").pop()}`,
          { type: compressedFile.type }
        );

        await handleUpload(compressedFileWithNewName, true);
      }
    } catch (error) {
      console.error("Error al manejar el archivo:", error);
    }
  };

  const handleUpload = async (selectedFile, isCompressed = false) => {
    if (!selectedFile) return;
    setLoading(true);
    const storageRef = ref(storage, `uploads/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Error uploading file:", error);
        setLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        if (isCompressed) {
          setCompressedFileUrl(downloadURL);
        } else {
          setFileUrl(downloadURL);
        }
        setLoading(false);
        console.log("File available at", downloadURL);
      }
    );
  };

  const handleAddPortfolio = async () => {
    if (images.length + documents.length + 1 > MAX_FILES) {
      setErrorMessage(`Ya tienes ${MAX_FILES} archivos en tu portafolio.`);
      return;
    }

    const newPortfolio = {
      fsp_file: fileUrl,
      fsp_thumbnail: compressedFileUrl || fileUrl,
      fsp_name: file.name,
      fsp_type: file.type,
      fre_id: free.fre_id,
    };

    await createFreelancerPortfolio(newPortfolio);
    handleCloseAdd();
    handlePortfolio();
  };

  const handleDeletePortfolio = async () => {
    try {
      await deleteFreePortfolio(portfolioIdToDelete);
      deleteFileByUrl(portfolioUrl);
    } catch (error) {
      console.error("Error al eliminar el portafolio:", error);
    }
    handleCloseDelete();
    handlePortfolio();
  };

  const deleteFileByUrl = async (url) => {
    try {
      const filePath = decodeURIComponent(
        decodeURIComponent(
          url.split("/o/")[1].split("?")[0].replace(/%2F/g, "/")
        )
      );
      const fileRef = storageRef(storage, filePath);

      await deleteObject(fileRef);
      handlePortfolio();
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
    }
  };

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [portfolioIdToDelete, setPortfolioIdToDelete] = useState(null);
  const [portfolioUrl, setPortfolioUrl] = useState(null);

  const handleAdd = () => {
    setErrorMessage("");
    setOpenAdd(true);
  };
  const handleCloseAdd = () => setOpenAdd(false);
  const handleDelete = (id, url) => {
    setPortfolioIdToDelete(id);
    setPortfolioUrl(url);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const styleAdd = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "44%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "42%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "auto",
    borderRadius: "10px",
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      <Button className="button-add" sx={{ mb: "20px" }} onClick={handleAdd}>
        Agregar portafolio
      </Button>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress size={28} sx={{ color: "#eba34f" }} />
        </Box>
      ) : (
        <ImageList className="image-list" cols={isMobile ? 2 : 3}>
          {images.map((image) => (
            <Box key={image.fsp_id} className="box-image">
              <ImageListItem>
                <img
                  srcSet={`${image.fsp_thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image.fsp_thumbnail}?w=164&h=164&fit=crop&auto=format`}
                  alt={image.fsp_name}
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
              </ImageListItem>
              <Box className="box-button-image">
                <Button>
                  <DeleteOutlineOutlinedIcon
                    className="delete-icon"
                    onClick={() => handleDelete(image.fsp_id, image.fsp_file)}
                  />
                </Button>
              </Box>
            </Box>
          ))}
        </ImageList>
      )}

      {documents.map((document) => (
        <Box key={document.fsp_id} className="box-document">
          <a
            href={document.fsp_file}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography className="text-doc">{document.fsp_name}</Typography>
          </a>
          <DeleteOutlineOutlinedIcon
            className="delete-icon"
            onClick={() => handleDelete(document.fsp_id, document.fsp_file)}
          />
        </Box>
      ))}

      {/* Modal Add portfolio */}
      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(62, 16, 81, 0.8)",
          },
        }}
      >
        <Box sx={styleAdd}>
          <Box sx={{ maxHeight: "600px", overflowY: "auto" }}>
            <Typography id="modal-add" className="modal-add-title">
              Agregar servicio
            </Typography>
            <ImageList
              className="image-list"
              cols={isMobile ? 3 : 5}
              sx={{
                gridAutoRows: isMobile ? "65px" : "75px",
                gap: isMobile ? "7px" : "15px",
                mb: "20px",
              }}
            >
              {images.map((image) => (
                <Box
                  key={image.fsp_id}
                  className="box-image"
                  sx={{ height: "75px" }}
                >
                  <ImageListItem>
                    <img
                      srcSet={`${image.fsp_file}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${image.fsp_file}?w=164&h=164&fit=crop&auto=format`}
                      alt={image.fsp_name}
                      loading="lazy"
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                    />
                  </ImageListItem>
                </Box>
              ))}
            </ImageList>
            <input
              type="file"
              id="upload-photo"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              color="primary"
              component="span"
              onClick={() => document.getElementById("upload-photo").click()}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                color: "#747684",
                border: "1px solid #DBDCE5",
                borderRadius: "10px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{ color: "#747684", marginRight: 1 }}
                />
              ) : (
                "Seleccionar archivos"
              )}
            </Button>
            {fileUrl && (
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {file.name}
              </Typography>
            )}
            {errorMessage && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}
            <Box className="modal-box-buttons">
              <Button className="modal-button button1" onClick={handleCloseAdd}>
                Cancelar
              </Button>
              <Button
                className="modal-button button2"
                onClick={handleAddPortfolio}
                disabled={loading}
              >
                {loading ? "Cargando archivo..." : "Guardar"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Modal Delete portfolio */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(62, 16, 81, 0.8)",
          },
        }}
      >
        <Box sx={styleDelete}>
          <Box>
            <Typography
              id="modal-add"
              className="modal-add-title"
              sx={{ fontSize: "16px !important" }}
            >
              Eliminar portafolio
            </Typography>
            <FormLabel
              className="modal-add-subtitle"
              sx={{ color: "#747684 !important" }}
            >
              ¿Seguro que deseas eliminar esto?
            </FormLabel>
            <Box className="modal-box-buttons">
              <Button
                className="modal-button button1"
                onClick={handleCloseDelete}
              >
                Cancelar
              </Button>
              <Button
                className="modal-button button2"
                onClick={handleDeletePortfolio}
              >
                Si, eliminar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
