import {
  Container,
  Box,
  Grid2,
  FormLabel,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import { getPlansPyme } from "../../services/pymeService";
import { createPayment } from "../../services/paymentService";

function Plans() {
  const [activePlan, setActivePlan] = useState("anual");
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [annualPlans, setAnnualPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usrId, setUsrId] = useState();

  const loadUserLogin = () => {
    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      const parsedLogin = JSON.parse(savedUser);
      setUsrId(parsedLogin);
    } else {
      console.error("No se encontró 'user-login' en localStorage");
      setTimeout(loadUserLogin, 500);
    }
  };

  useEffect(() => {
    loadUserLogin();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPlansPyme();
        const data = response.data.data;

        if (!Array.isArray(data)) {
          throw new Error("Expected data to be an array");
        }

        const formatPrice = (price) => {
          return price.toLocaleString("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
          });
        };

        // Plan Mensual
        const monthlyPlans = data
          .filter((plan) => plan.pla_type === "mensual")
          .map((plan) => ({
            ...plan,
            pla_price: formatPrice(plan.pla_price),
            arrayContent: plan.pla_content
              .split(".")
              .map((item) => item.trim())
              .filter(Boolean),
          }));
        setMonthlyPlans(monthlyPlans);

        // Plan Anual
        const annualPlans = data
          .filter((plan) => plan.pla_type === "anual")
          .map((plan) => ({
            ...plan,
            pla_price: formatPrice(plan.pla_price),
            arrayContent: plan.pla_content
              .split(".")
              .map((item) => item.trim())
              .filter(Boolean),
          }));
        setAnnualPlans(annualPlans);
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePlanChange = (plan) => {
    setActivePlan(plan);
  };

  const plansToShow = activePlan === "anual" ? annualPlans : monthlyPlans;
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleHirePlan = async (planId, plaPrice) => {
    const numericPrice = parseInt(plaPrice.replace(/[^0-9]/g, ""), 10);
    localStorage.setItem("selected-plan-id", planId);
    localStorage.setItem("selected-plan-price", numericPrice);

    try {
      const data = {
        sessionId: `session-${Date.now()}`,
        buyOrder: `order-${Date.now()}`,
        amount: numericPrice,
        returnUrl: `${process.env.REACT_APP_API_URL}/transbank/success?usrId=${usrId.id}`,
      };
      const response = await createPayment(data);

      if (response.url && response.token) {
        window.location.href = `${response.url}?token_ws=${response.token}`;
      } else {
        console.error("Error: No se recibió la URL de Transbank");
      }
    } catch (error) {
      console.error("Error al iniciar la transacción", error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Grid2
            container
            rowSpacing={5}
            columnSpacing={2}
            sx={{ pt: "5%", flexDirection: "column" }}
          >
            <FormLabel
              sx={{
                color: "#3E1051",
                fontFamily: "Libre Baskerville",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "21.78px",
                textAlign: "center",
              }}
            >
              <b style={{ color: "#ED8131" }}>Planes</b>
            </FormLabel>
            <FormLabel
              sx={{
                color: "#3E1051",
                fontFamily: "Libre Baskerville",
                fontWeight: "700",
                fontSize: "28px",
                textAlign: "center",
              }}
            >
              <b style={{ color: "#FFFFFF" }}>Inicia, Avanza y Consolida.</b>
            </FormLabel>
            <FormLabel
              sx={{
                color: "#3E1051",
                fontStyle: "Inter",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "21.78px",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#FFFFFF" }}>
                Los servicios que entrega Dreup son planes de suscripción en los
                que las Mipymes encuentran una oferta de planes y productos
                claves para impulsar la gestión de sus negocios y que sean parte
                de los estándares de negocios sostenibles.
              </p>
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 3,
              }}
            >
              <Button
                onClick={() => handlePlanChange("anual")}
                sx={{
                  color: activePlan === "anual" ? "#ED8131" : "#FFFFFF",
                  fontFamily: "Libre Baskerville",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Anual
              </Button>
              <Button
                onClick={() => handlePlanChange("mensual")}
                sx={{
                  color: activePlan === "mensual" ? "#ED8131" : "#FFFFFF",
                  fontFamily: "Libre Baskerville",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Mensual
              </Button>
            </Box>
          </Grid2>
        </Box>
      </Container>

      <Container
        maxWidth="false"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "85%",
            flexDirection: "column",
          }}
        >
          <Grid2 container spacing={1}>
            {plansToShow.map((plan, index) => (
              <Grid2 item sx={{ width: { xs: "100%", md: "32%" } }} key={index}>
                <Card
                  sx={{
                    border: isMobile ? "0" : "2px solid #FFFFFF",
                    borderTopLeftRadius: isMobile ? "0" : "20px",
                    borderTopRightRadius: isMobile ? "0" : "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      backgroundColor: "#3E1051",
                      alignItems: "center",
                      textAlign: "center",
                      pb: "25px",
                      background:
                        "linear-gradient(to bottom, #3E1051, #4D1564 50%, #5A1A75)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        pt: "20px",
                        color: "#FFFFFF",
                        fontFamily: "Libre Baskerville",
                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "21.78px",
                        textAlign: "center",
                      }}
                    >
                      {plan.pla_title}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        pt: "25px",
                        pb: "25px",
                        color: "#ED8131",
                        fontFamily: "Libre Baskerville",
                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "21.78px",
                        textAlign: "center",
                      }}
                    >
                      {plan.pla_price}
                    </Typography>

                    <Button
                      sx={{
                        backgroundColor: "#E3602D",
                        color: "#FFFFFF",
                        marginTop: "10px",
                        borderRadius: "10px",
                        fontStyle: "Inter",
                        fontWeight: "700",
                        fontSize: "18px",
                        textTransform: "none",
                        minWidth: isMobile ? "95%" : "70%",
                      }}
                      onClick={() =>
                        handleHirePlan(plan.pla_id, plan.pla_price)
                      }
                    >
                      Contratar Plan <ArrowForwardIcon sx={{ ml: "8px" }} />
                    </Button>
                  </CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      pt: "25px",
                      pb: "25px",
                      color: "#747684",
                      fontStyle: "Inter",
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "21.78px",
                      textAlign: "center",
                    }}
                  >
                    {plan.pla_subtitle}
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#3E1051" }}>
                      {plan.arrayContent?.map((item, index) => (
                        <div key={index}>
                          <Typography
                            variant="body2"
                            sx={{
                              margin: "10px 0",
                              color: "#747684",
                              fontStyle: "Inter",
                              fontSize: "20px",
                              fontWeight: "400",
                            }}
                          >
                            <CheckIcon sx={{ color: "#30B73D", mr: "8px" }} />{" "}
                            {item}
                          </Typography>
                        </div>
                      ))}
                    </Typography>
                  </Box>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>
    </>
  );
}

export default Plans;
