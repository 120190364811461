import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  Link,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldCustom from "../../components/shared/TextField/TextFieldCustom.jsx";
import RadioCustom from "../../components/shared/RadioCustom/RadioCustom.jsx";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import BtnCustom from "../../components/shared/Btns/btnCustom.jsx";
import { createFreelancer } from "../../services/freelancerService.js";
import SelectCustom from "../../components/shared/Select/SelectCustom.jsx";
import { createSocialMedia, getUserByEmail, loginGoogleFacebook } from "../../services/userService.js";
import { useNavigate } from "react-router-dom";

const laboralSituations = [
  { value: 1, label: "Dependiente Jornada completa (puedo acomodar horarios)" },
  {
    value: 2,
    label: "Independiente sin horario fijo (puedo acomodar horarios)",
  },
  { value: 3, label: "Independiente horario fijo (puedo acomodar horarios)" },
  {
    value: 4,
    label: "Socio (a) - Dueño(a)  de negocio (puedo acomodar  mis horarios)",
  },
  { value: 5, label: "Sin empleo actual" },
];

export default function Experience() {
  const navigate = useNavigate();
  const [professionalExperience, setProfessionalExperience] = useState("");
  const [whyJoin, setWhyJoin] = useState("");
  const [employmentSituation, setEmploymentSituation] = useState(null);
  const [socialMedia, setSocialMedia] = useState([
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
    { som_name: "", som_url: "", usr_id: 0 },
  ]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  let freelancerObject;
  let userObject;

  //LocalStorage
  const savedFreelancer = localStorage.getItem("freelancer");
  const savedUser = localStorage.getItem("user");

  if (savedFreelancer) {
    freelancerObject = JSON.parse(savedFreelancer);
  }

  if (savedUser) {
    userObject = JSON.parse(savedUser);
  }

  useEffect(() => {
    const dateUser = async () => {      
        const dataUser = await getUserByEmail(userObject.email);
        setUser(dataUser.data);     
    };

    dateUser();
  }, []);

  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index][field] = value;
    setSocialMedia(updatedSocialMedia);
  };

  const handleVolver = () => {
    navigate(`/create-account/specialization/?isPyme=false`);
  };

  // Función para validar URLs
  const validateURL = (url) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  const handleContinue = async () => {
    setLoading(true)
    let newErrors = {};

    // Validación de cada campo
    if (!professionalExperience)
      newErrors.professionalExperience = "Este campo es requerido";

    if (!whyJoin) newErrors.whyJoin = "Este campo es requerido";

    if (!employmentSituation)
      newErrors.employmentSituation = "Debes seleccionar una situación laboral";

    if (!termsAccepted)
      newErrors.termsAccepted = "Debes aceptar los términos y condiciones";

    // Validar que al menos una red social tenga nombre y URL
    const selectedSocialMedia = socialMedia.filter(
      (media) => media.som_name !== "" && media.som_url !== ""
    );

    // Validación de URLs de redes sociales usando validateURL
    socialMedia.forEach((media, index) => {
      if (media.som_url && !validateURL(media.som_url)) {
        newErrors.socialMedia = `La URL en la red social "${media.som_url}" no es válida`;
      }
    });

    if (selectedSocialMedia.length === 0) {
      newErrors.socialMedia =
        "* Debes seleccionar al menos una red social con su respectivo enlace";
    }

    // Si hay errores, actualiza el estado de errores
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const finalRut = freelancerObject.fre_rut + freelancerObject.rut; 
      const newFreelancer = {
        fre_full_name: freelancerObject.fre_full_name,
        fre_phone: freelancerObject.fre_phone,
        fre_rut: finalRut,
        fre_age_range: freelancerObject.fre_age_range,
        specialization: freelancerObject.specialization,
        fre_professional_experience: professionalExperience,
        fre_why_join: whyJoin,
        fre_employment_situation: employmentSituation,
        usr_id: userObject.id || user,
      };
      try {
        await createFreelancer(newFreelancer);
        // Recorre las redes sociales y llama a createSocialMedia si los campos no están vacíos
        await Promise.all(
          socialMedia.map(async (media) => {
            if (media.som_name && media.som_url) {
              const socialMediaData = {
                som_name: media.som_name,
                som_url: media.som_url,
                usr_id: userObject.id || user,
              };
              await createSocialMedia(socialMediaData);
            }
          })
        );
        setSuccessMessage("Freelancer registrado exitosamente");
        setSnackbarType("success");
        setOpenSnackbar(true);
        try {
          const responseLogin = await loginGoogleFacebook(userObject.email);
          const dataUser = {
            email: userObject.email,
            id: responseLogin.data.usr_id,
            type: "",
            isCreate: responseLogin.data.isCreate,
          };
          const userLogin = {
            usr_email: userObject.email,
            usr_id: responseLogin.data.usr_id,
            access_token: responseLogin.data.access_token,
            refreshToken: responseLogin.data.refreshToken,
            usr_type: responseLogin.data.usr_type,
            isCreate: responseLogin.data.isCreate,
          };

          localStorage.setItem("user-login", JSON.stringify(userLogin));
          localStorage.setItem("user", JSON.stringify(dataUser));
          navigate(`/home`);
          setLoading(false)
        } catch (error) {
          setErrorMessage("Error al iniciar sesión.");
          setSnackbarType("error");
          setOpenSnackbar(true);
          navigate(`/login`);
          setLoading(false)
        }
        // navigate(`/login`);
      } catch (error) {
        setErrorMessage("Error al crear el freelancer verifique los datos.");
        setSnackbarType("error");
        setOpenSnackbar(true);
        setLoading(false)
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
          width: "80%",
          flexDirection: "column",
        }}
      >
        <Grid2
          container
          rowSpacing={5}
          columnSpacing={2}
          sx={{ pt: "5%", pb: "5%" }}
        >
          {/* Experiencia profesional */}

          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={"Experiencia profesional"}
              nameTextField={"fre_professional_experience"}
              required={true}
              helperTopText={
                <p>
                  Cuéntanos una breve reseña laboral de tu vida. <br />
                  <br /> Trata de destacar los hitos más importantes que te han
                  llevado a ser un experto en el área que puedes ayudar y
                  contribuir al crecimiento de las Pymes
                </p>
              }
              multiline={true}
              rows={5}
              value={professionalExperience}
              handleChange={(e) => setProfessionalExperience(e.target.value)}
              errors={!!errors.professionalExperience}
              helperText={errors.professionalExperience}
            />
          </Grid2>

          {/* Pregunta freelance */}
          <Grid2 size={{ xs: 12 }}>
            <TextFieldCustom
              label={
                "¿Por qué te gustaría unirte a Dreup y ayudar a las PYMES a crecer?"
              }
              nameTextField={"fre_why_join"}
              required={true}
              multiline={true}
              rows={5}
              value={whyJoin}
              handleChange={(e) => setWhyJoin(e.target.value)}
              errors={!!errors.whyJoin}
              helperText={errors.whyJoin}
            />
          </Grid2>

          {/* situacion laboral */}

          <Grid2 size={{ xs: 12 }}>
            <Typography
              sx={{
                color: "#3E1051",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "21.78px",
              }}
            >
              Cuéntanos sobre tu actual situación laboral{" "}
              {<b style={{ color: "red" }}>*</b>}
            </Typography>
            <FormControl fullWidth>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="fre_employment_situation"
                value={employmentSituation}
                onChange={(e) => setEmploymentSituation(e.target.value)}
              >
                {laboralSituations.map((situation, index) => (
                  <FormControlLabel
                    key={index}
                    value={situation.label}
                    control={<RadioCustom />}
                    label={situation.label}
                    sx={{
                      color: "#747684",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: "400",
                      flexGrow: 1,
                    }}
                  />
                ))}
              </RadioGroup>
              {errors.employmentSituation && (
                <Typography color="error">
                  {errors.employmentSituation}
                </Typography>
              )}
            </FormControl>
          </Grid2>
          {/* Redes sociales */}

          <Grid2 size={{ xs: 12 }} sx={{ width: "100%", mt: "5% !important" }}>
            <Grid2 size={{ xs: 12 }}>
              {/* Enlace a tu Portafolio */}
              <FormLabel
                sx={{
                  color: "#3E1051",
                  fontStyle: "Inter",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "21.78px",
                }}
              >
                Enlace a tu Portafolio ({" "}
                {
                  <b style={{ color: "#ED8131" }}>
                    {" "}
                    Linkedin, Facebook, Instagram, GitHub, Gitlab, Web, Canvas,
                    Youtube, Pinteres.
                  </b>
                }{" "}
                ) {<b style={{ color: "red" }}>*</b>}
              </FormLabel>
            </Grid2>
            <Grid2>
              <FormLabel
                sx={{
                  color: "#747684",
                  fontStyle: "Inter",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21.78px",
                }}
              >
                Escribe tus 5 redes principales
              </FormLabel>
              {socialMedia.map((item, index) => (
                <Grid2
                  container
                  direction="row"
                  rowSpacing={1}
                  columnSpacing={3}
                  key={index}
                >
                  <Grid2 sx={{ width: { xs: "100%", md: "35%" }, mt: "5px" }}>
                    <SelectCustom
                      showAsterisk={false}
                      required
                      value={item.som_name}
                      nameSelect="som_name"
                      options={[
                        { value: "Linkedin", label: "Linkedin" },
                        { value: "Facebook", label: "Facebook" },
                        { value: "Instagram", label: "Instagram" },
                        { value: "GitHub", label: "GitHub" },
                        { value: "Gitlab", label: "Gitlab" },
                        { value: "Web", label: "Web" },
                        { value: "Canvas", label: "Canvas" },
                        { value: "Youtube", label: "Youtube" },
                        { value: "Pinteres", label: "Pinteres" },
                      ]}
                      handleChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "som_name",
                          e.target.value
                        )
                      }
                    />
                  </Grid2>
                  <Grid2 sx={{ width: { xs: "100%", md: "61%" }, mt: "5px" }}>
                    <TextFieldCustom
                      required={true}
                      showAsterisk={false}
                      nameTextField="som_url"
                      value={item.som_url}
                      handleChange={(e) =>
                        handleSocialMediaChange(
                          index,
                          "som_url",
                          e.target.value
                        )
                      }
                    />
                  </Grid2>
                </Grid2>
              ))}
              {errors.socialMedia && (
                <Typography color="error">{errors.socialMedia}</Typography>
              )}
            </Grid2>
          </Grid2>
          {/* TyC */}
          <Grid2 size={{ xs: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  sx={{
                    color: "#c94e1f",
                    "&.Mui-checked": {
                      color: "#E3602D",
                    },
                  }}
                />
              }
              label={
                <Link
                  href="https://dreup.cl"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  style={{ fontFamily: "Inter", color: "#3E1051" }}
                >
                  Acepto los términos y condiciones{" "}
                  <b style={{ color: "red" }}>*</b>
                </Link>
                // <p style={{ fontFamily: "Inter" }}>
                //   Acepto los términos y condiciones{" "}
                //   <b style={{ color: "red" }}>*</b>
                // </p>
              }
            />
          </Grid2>
        </Grid2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pb: "5%",
          }}
        >
          <BtnCustom
            startIcon={<ArrowBackRounded />}
            label="Volver"
            variant={"text"}
            onClick={handleVolver}
          />
          <BtnCustom
            endIcon={<ArrowForwardRounded />}
            label={loading ? (
              <CircularProgress
                size={24}
                sx={{ color: "#747684", marginRight: 1 }}
              />
            ) : (
              "Continuar"
            )}
            onClick={handleContinue}
          />
        </Box>
      </Box>
      {/* Alerta flotante */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
