import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { confirmUser, getUserById } from "../../../services/userService";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/slices/userSlice";
import { LoadingButton } from "@mui/lab";

const CardConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [snackbarType, setSnackbarType] = useState("success");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoadind, setIsLoadin] = useState(false);

  const location = useLocation();
  // Obtener los parámetros de la URL
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code'); // Aquí capturas el parámetro "code"
  const id = queryParams.get('id'); // Aquí capturas el parámetro "code"

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  const handleConfirm = async () => {
    try {
      setIsLoadin(true)
      const data = {
        code: Number(code),
        id: Number(id)
      }
      await confirmUser(data)
      const newUser = await getUserById(id)
      const dataUser = {
        email: newUser.data.usr_email,
        id: newUser.data.usr_id,
        type: '',
      }
      dispatch(setUser(dataUser))
      setIsLoadin(false)
      navigate("/selectType");
    } catch (error) {
      setErrorMessage("Error al confirmar el usuario. Intente mas tarde");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setIsLoadin(false)
    }
  }

  return (
    <Card className="cardConfirm" sx={{ minWidth: 275 }}>
      <CardContent className="card-text-confirm">
        <Typography className="text-confirm" component="div">
          ¡Gracias por confirmar tu correo electrónico!
        </Typography>
      </CardContent>
      <CardActions className="card-text-confirm-2">
        <LoadingButton
           loading={isLoadind}
          className="btn-confirm"
          sx={{
            textTransform: "none",
          }}
          onClick={handleConfirm}
          loadingIndicator={
            <CircularProgress size={24} sx={{ color: "white" }} />
          }
        >
          Continuar <ArrowForwardIcon />
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarType === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CardConfirm;
