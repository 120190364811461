import React from "react";
import Navbar from "../../components/shared/navbarSignup";
import { Box, Typography } from "@mui/material";
import CardSelectType from "../../components/shared/cards/cardSelectType";

const SelectType = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          marginTop: { xs: 3, sm: 4, md: 4, lg: 7, xl: 9 },
        }}
      >
        <Navbar />
      </Box>
      <Box
        className="signup-login-container-2"
        sx={{
          marginTop: { xs: 3, sm: 4, md: 4, lg: 5, xl: 7 },
        }}
      >
        <Typography
          className="text-type"
          sx={{
            marginBottom: { xs: -3, sm: 4, md: 4, lg: 14, xl: -2 },
          }}
        >¿Cómo deseas registrarte?</Typography>
        <CardSelectType />
      </Box>
    </div>
  );
};

export default SelectType;
