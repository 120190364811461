import { Radio } from '@mui/material';
import React from 'react';

export default function RadioCustom(props) {
  return (
    <Radio
      sx={{
        '& .MuiSvgIcon-root': {
          borderRadius: '50%',
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          color: '#ED8131', // Color del centro cuando está seleccionado
        },
      }}
      {...props}
    />
  );
}
