import React from "react";
import "../Styles/signup_login.css";
import Navbar from "../../components/shared/navbarSignup";
import SignupBox from "../../components/shared/signupBox";

const Login = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Navbar />
      <div className="signup-login-container">
        <SignupBox isLogin={true}/>
        <h1 className="text-inferior-login">
          ¿Aún no tienes una cuenta ? <br/>
          <a
            href="/signup"
            className="iniciar-sesion"
          >
            Crear cuenta
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Login;
