import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Signup from "../views/signup/signup";
import RegistrationEmail from "../views/signup/registrationEmail";
import ConfirmEmail from "../views/signup/confirmEmail";
import SelectType from "../views/signup/selectType";
import LayoutSignUp from "../views/layout/layoutSignUp";
import Specialization from "../views/signup/specialization";
import Experience from "../views/signup/experience";
import PersonalData from "../views/signup/personalData";
import ExperiencePyme from "../views/signup/experiencePyme";
import Plans from "../views/signup/plans";
import Login from "../views/login/login";
import RecoverPassword from "../views/login/recoverPassword";
import NewPassword from "../views/login/newPassword";
import FileUpload from "../views/fileUpload";
import Freelancer from "../views/account/freelancer";
import Home from "../views/home/home";
import PaymentError from "../views/error/paymentError";
import ErrorNotFound from "../views/error/error404";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    const whiteBackgroundRoutes = [
      "/account/freelancer",
      "/home",
      "/payment-error",
    ];
    if (whiteBackgroundRoutes.includes(location.pathname)) {
      document.body.style.backgroundColor = "#fff";
    } else {
      document.body.style.backgroundColor = "#3e1051";
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />

      <Route path="/signup" element={<Signup />} />
      <Route path="/registrationEmail" element={<RegistrationEmail />} />
      <Route path="/confirmEmail" element={<ConfirmEmail />} />
      <Route path="/selectType" element={<SelectType />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/login" element={<Login />} />
      <Route path="/file-upload" element={<FileUpload />} />
      <Route path="/payment-error" element={<PaymentError />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="/recover-password/new-password" element={<NewPassword />} />
      <Route path="/create-account" element={<LayoutSignUp />}>
        <Route path="personal" element={<PersonalData />} />
        <Route path="specialization" element={<Specialization />} />
        <Route path="experience" element={<Experience />} />
        <Route path="experience/pyme" element={<ExperiencePyme />} />
      </Route>
      {/* Protege las rutas sensibles con el componente ProtectedRoute */}
      <Route
        path="/account/freelancer"
        element={<ProtectedRoute element={<Freelancer />} />}
      />
      <Route path="/home" element={<ProtectedRoute element={<Home />} />} />

      {/* Ruta 404 */}
      <Route path="*" element={<ErrorNotFound />} />
    </Routes>
  );
};

const App = () => (
  <Router>
    <AppRoutes />
  </Router>
);

export default App;
