import React from 'react'
import Navbar from '../../components/shared/navbarSignup'
import CardRegistration from '../../components/shared/cards/cardregistration'


const RegistrationEmail = () => {
  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh'
    }}
  >
    <Navbar />
    <CardRegistration />
    
  </div>
  )
}

export default RegistrationEmail